import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";
import BaseService from "./base.service";

export default class apiCancelPolicyService extends BaseService {
  getListCancelPolicy = (values) => {
    return this.axios.post(API_ENDPOINTS.GET_CANCEL_POLICY, values);
  };

  crawlDataCancelPolicy = (data) => {
    return this.axios.post(API_ENDPOINTS.CRAWL_DATA_CANCEL_POLICY, data);
  }
}