const actions = {
  GET_CRAWL_SALE_STATISTIC_REQUEST: "GET_CRAWL_SALE_STATISTIC_REQUEST",
  GET_CRAWL_SALE_STATISTIC_SUCCESS: "GET_CRAWL_SALE_STATISTIC_SUCCESS",
  GET_CRAWL_SALE_STATISTIC_ERROR: "GET_CRAWL_SALE_STATISTIC_ERROR",

  CRAWL_SALE_STATISTIC_REQUEST: "CRAWL_SALE_STATISTIC_REQUEST",
  CRAWL_SALE_STATISTIC_SUCCESS: "CRAWL_SALE_STATISTIC_SUCCESS",
  CRAWL_SALE_STATISTIC_ERROR: "CRAWL_SALE_STATISTIC_ERROR",

  crawlSaleStatistic: (payload) => ({
    type: actions.CRAWL_SALE_STATISTIC_REQUEST,
    payload,
  }),

  getCrawlSaleStatistic: (payload) => ({
    type: actions.GET_CRAWL_SALE_STATISTIC_REQUEST,
    payload,
  }),
};
export default actions;
