const actions = {
  GET_NOTIFY_REQUEST: "NOTIFY/GET_NOTIFY_REQUEST",
  GET_NOTIFY_SUCCESS: "NOTIFY/GET_NOTIFY_SUCCESS",
  GET_NOTIFY_ERROR: "NOTIFY/GET_NOTIFY_ERROR",

  UPDATE_STATUS_REQUEST: "NOTIFY/UPDATE_STATUS_REQUEST",
  UPDATE_STATUS_SUCCESS: "NOTIFY/UPDATE_STATUS_SUCCESS",
  UPDATE_STATUS_ERROR: "NOTIFY/UPDATE_STATUS_ERROR",

  TOGGLE_MODAL: "NOTIFY/TOOGLE_MODAL",
  SET_SEEN_NOTIFI: "SET_SEEN_NOTIFI",
  SET_SEEN_NOTIFI_ERROR: "SET_SEEN_NOTIFI_ERROR",

  GET_IS_CHECK: "GET_IS_CHECK",
  GET_IS_CHECK_SUCCESS: "GET_IS_CHECK_SUCCESS",
  GET_IS_CHECK_ERROR: "GET_IS_CHECK_ERROR",

  SET_IS_CHECK_NOTIFICATION: "SET_IS_CHECK_NOTIFICATION",
  SET_IS_CHECK_NOTIFICATION_SUCCESS: "SET_IS_CHECK_NOTIFICATION_SUCCESS",

  getNotify: (payload) => ({
    type: actions.GET_NOTIFY_REQUEST,
    payload,
  }),

  toggleModal: () => ({
    type: actions.TOGGLE_MODAL,
  }),

  updateStatus: (payload) => ({
    type: actions.UPDATE_STATUS_REQUEST,
    payload,
  }),

  getIsCheckNotification: (payload) => ({
    type: actions.GET_IS_CHECK,
    payload,
  }),
  setIsCheckNotification: (payload) => ({
    type: actions.SET_IS_CHECK_NOTIFICATION,
    payload,
  }),
  setSeenNotifi: (payload) => ({
    type: actions.SET_SEEN_NOTIFI,
    payload,
  }),
};

export default actions;
