import actions from "@iso/redux/apiCrawlRoom/actions";

const initialState = {
  apiCrawlRooms: [],
  listUser: [],
  room: {},
  total: 0,
  loading: true,
  crawlLoading: false,
  crawlDetailsLoading: false,
  openSaleLoading: false,
  stopSellingLoading: false,
};

export default function crawlRoom(state = initialState, action) {
  switch (action.type) {
    case actions.GET_CRAWL_ROOM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_CRAWL_ROOM_SUCCESS:
      return {
        ...state,
        apiCrawlRooms: action.apiCrawlRooms,
        total: action.total,
        loading: false,
      };
    case actions.GET_CRAWL_ROOM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.CRAWL_ROOM_REQUEST:
      return {
        ...state,
        crawlLoading: true,
      };
    case actions.CRAWL_ROOM_SUCCESS:
      return {
        ...state,
        crawlLoading: false,
      };
    case actions.CRAWL_ROOM_ERROR:
      return {
        ...state,
        crawlLoading: false,
        error: action.error,
      };
    case actions.GET_LIST_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_LIST_USER_SUCCESS:
      return {
        ...state,
        listUser: action.listUser,
        loading: false,
      };
    case actions.GET_LIST_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.CRAWL_ROOM_ALL_REQUEST:
      return {
        ...state,
        crawlLoading: true,
      };
    case actions.CRAWL_ROOM_ALL_SUCCESS:
      return {
        ...state,
        crawlLoading: false,
      };
    case actions.CRAWL_ROOM_ALL_ERROR:
      return {
        ...state,
        crawlLoading: false,
        error: action.error,
      };
    case actions.CRAWL_ROOM_DETAILS_REQUEST:
      return {
        ...state,
        crawlDetailsLoading: true,
      };
    case actions.CRAWL_ROOM_DETAILS_SUCCESS:
      return {
        ...state,
        crawlDetailsLoading: false,
      };
    case actions.CRAWL_ROOM_DETAILS_ERROR:
      return {
        ...state,
        crawlDetailsLoading: false,
        error: action.error,
      };
    case actions.OPEN_SALE_MULTIPLE_ROOMS_REQUEST:
      return {
        ...state,
        openSaleLoading: true,
      };
    case actions.OPEN_SALE_MULTIPLE_ROOMS_SUCCESS:
      return {
        ...state,
        openSaleLoading: false,
      };
    case actions.OPEN_SALE_MULTIPLE_ROOMS_ERROR:
      return {
        ...state,
        openSaleLoading: false,
        error: action.error,
      };
    case actions.STOP_SELLING_MULTIPLE_ROOMS_REQUEST:
      return {
        ...state,
        stopSellingLoading: true,
      };
    case actions.STOP_SELLING_MULTIPLE_ROOMS_SUCCESS:
      return {
        ...state,
        stopSellingLoading: false,
      };
    case actions.STOP_SELLING_MULTIPLE_ROOMS_ERROR:
      return {
        ...state,
        stopSellingLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
