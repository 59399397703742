import { all, takeEvery, put, fork, call } from "redux-saga/effects";

import { apiCrawlPlanService, taskService } from "@iso/services";
import actions from "@iso/redux/apiCrawlPlan/actions";

function* getListCrawlPlan() {
  yield takeEvery(actions.GET_CRAWL_PLAN_REQUEST, function* ({ payload }) {
    const {
      storeIds,
      keyword,
      keywordType,
      otaIds,
      createdUsers,
      createdAtStart,
      createdAtEnd,
      size,
      page,
      resolve,
      reject,
    } = payload;
    try {
      const response = yield call(apiCrawlPlanService.getListCrawlPlan, {
        storeIds,
        keyword,
        keywordType,
        otaIds,
        createdUsers,
        createdAtStart,
        createdAtEnd,
        size,
        page,
        resolve,
        reject,
      });
      yield put({
        type: actions.GET_CRAWL_PLAN_SUCCESS,
        apiCrawlPlans: response ? response.apiCrawlPlans : [],
        total: response ? response.total : 0
      });
      yield resolve(response.apiCrawlPlans);
    } catch (e) {
      yield put({
        type: actions.GET_CRAWL_PLAN_ERROR,
        error: e.message,
      });
    }
  });
}


function* crawlPlan() {
  yield takeEvery(actions.CRAWL_PLAN_REQUEST, function* ({ payload }) {
    const {
      storeId,
      keyword,
      keywordType,
      otaId,
      columnCrawls,
      typeCrawl,
      crawlPlanId,
      resolve,
      reject,
    } = payload;
    try {
      const response = yield call(apiCrawlPlanService.crawlPlan, {
        storeId,
        keyword,
        keywordType,
        otaId,
        columnCrawls,
        typeCrawl,
        crawlPlanId,
        resolve,
        reject,
      });
      yield put({
        type: actions.CRAWL_PLAN_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.CRAWL_PLAN_ERROR,
        error: e.message,
      });
      reject(e)
    }
  });
}

function* getListUser() {
  yield takeEvery(actions.GET_LIST_USER_REQUEST, function* ({ payload }) {
    const { list_role } = payload;
    try {
      const [assignees] = yield all([
        call(taskService.getAssignees, { list_role }),
      ]);
      yield put({
        type: actions.GET_LIST_USER_SUCCESS,
        listUser: assignees ? assignees.accounts : [],
      });
    } catch (e) {
      yield put({
        type: actions.GET_LIST_USER_ERROR,
        error: e.message,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getListCrawlPlan),
    fork(crawlPlan),
    fork(getListUser),
  ]);
}
