import { all, takeEvery, put, fork, call } from "redux-saga/effects";

import {taskCopyHistoryService} from "@iso/services";
import actions from "@iso/redux/taskCopyHistory/actions";

function* getTaskCopyHistory() {
  yield takeEvery(actions.GET_TASK_COPY_HISTORY_REQUEST, function* ({ payload }) {
    const {
      searchKeyword,
      endDateStart,
      endDateEnd,
      size,
      page,
      resolve,
      reject,
    } = payload;
    try {
      const response = yield call(taskCopyHistoryService.getTaskCopyHistory, {
        searchKeyword,
        endDateStart,
        endDateEnd,
        size,
        page,
        resolve,
        reject,
      });
      yield put({
        type: actions.GET_TASK_COPY_HISTORY_SUCCESS,
        tasks: response ? response.tasks : [],
        total: response ? response.total : 0
      });
      yield resolve(response.tasks);
    } catch (e) {
      yield put({
        type: actions.GET_TASK_COPY_HISTORY_ERROR,
        error: e.message,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getTaskCopyHistory)]);
}
