import { all, takeLatest, takeEvery, put, fork, call } from "redux-saga/effects";
import { get, reverse, pickBy, pick, identity } from "lodash";

import {
  taskService,
  planAmountService,
  notifyService,
  downloadService,
  accountService,
  otaService,
  contentService, helpService
} from "@iso/services";
import actions from "./actions";
import {TASK_CONTENT_TYPES, TASK_STOP_WATCH} from "@iso/constants/select.constant";
import planAmountActions from "../planAmount/actions";
import notifyActions from "../notify/actions";

export function* getTaskDetail() {
  yield takeLatest(actions.GET_TASK_DETAIL_REQUEST, function* ({ payload }) {
    const { taskId, resolve, reject } = payload;
    try {
      const task = yield call(taskService.getTaskDetail, { taskId });
      if (task.extraField !== null && task.extraField !== undefined) {
        task.extraField = JSON.parse(task.extraField)
      }
      if (task.executedTeam !== null && task.executedTeam !== undefined) {
        task.executedTeam = task.executedTeam.split(",")
      } else {
        task.executedTeam = []
      }

      if (task.personInCharge !== null && task.personInCharge !== undefined && task.personInCharge !== "") {
        task.personInCharge = task.personInCharge.split(",").map(Number);
      } else {
        task.personInCharge = []
      }
      yield put({
        type: actions.GET_TASK_DETAIL_SUCCESS,
        task,
      });
      resolve({});
    } catch (e) {
      yield put({ type: actions.GET_TASK_DETAIL_ERROR });
      reject(e);
    }
  });
}

export function* getTaskMetadata() {
  yield takeLatest(actions.GET_TASK_METADATA_REQUEST, function* ({ payload }) {
    const { values, currentAccount } = payload;
    try {
      const [categoryList, userAssigneeList, planDefaultList] = yield all([
        call(taskService.getCategoryList),
        call(taskService.getUserAssigneeList, { ...values, currentAccount }),
        call(planAmountService.fetchDefaultPlans),
      ]);
      yield put({
        type: actions.GET_TASK_METADATA_SUCCESS,
        categoryList: categoryList?.rows || [],
        planDefaultList: planDefaultList?.rows || [],
        userAssigneeList: userAssigneeList.accounts || [],
      });
    } catch (e) {
      yield put({ type: actions.GET_TASK_METADATA_ERROR });
    }
  });
}

export function* getCommentUserNotifyRequest() {
  yield takeLatest(actions.GET_COMMENT_USER_NOTIFY_REQUEST, function* ({ payload }) {
    const { id, type } = payload;
    try {
      const response = yield call(taskService.getCommentUserNotifyRequest, {
        id,
        type,
      });
      yield put({
        type: actions.GET_COMMENT_USER_NOTIFY_SUCCESS,
        userNotifyList: get(response, "accounts", []),
        chainNotifyList: get(response, "chains", []),
      });
    } catch (e) {
      yield put({ type: actions.GET_COMMENT_USER_NOTIFY_ERROR });
    }
  });
}

export function* createTask() {
  yield takeLatest(actions.ADD_TASK_REQUEST, function* ({ payload }) {
    const { data, resolve, reject } = payload;
    try {
      const response = yield call(taskService.createTask, { ...data });
      for (let i = 0; i < response.length; i++) {
        if (response[i].executedTeam !== null && response[i].executedTeam !== undefined) {
          response[i].executedTeam = response[i].executedTeam.split(",")
        } else {
          response[i].executedTeam = []
        }
      }
      if (response.extraField !== null && response.extraField !== undefined) {
        response.extraField = JSON.parse(response.extraField)
      }
      yield put({
        type: actions.ADD_TASK_SUCCESS,
        // task,
      });
      yield put({ type: planAmountActions.RESET_PLAN_AMOUNT });
      yield resolve(response);
    } catch (e) {
      yield put({ type: actions.ADD_TASK_ERROR });
      reject(e);
    }
  });
}

export function* editTask() {
  yield takeLatest(actions.EDIT_TASK_REQUEST, function* ({ payload }) {
    const { taskId, accountId, data, resolve, reject } = payload;
    try {
      const task = yield call(taskService.editTask, {
        taskId: +taskId,
        data,
      });
      if (task.executedTeam !== null && task.executedTeam !== undefined) {
        task.executedTeam = task.executedTeam.split(",")
      } else {
        task.executedTeam = []
      }

      if (task.extraField !== null && task.extraField !== undefined) {
        task.extraField = JSON.parse(task.extraField)
      }

      if (task.personInCharge !== null && task.personInCharge !== undefined) {
        task.personInCharge = task.personInCharge.split(",").map(Number);
      } else {
        task.personInCharge = []
      }
      const isCheckTotal = yield call(notifyService.getIsCheckNotification,  accountId );
      yield put({
        type: notifyActions.GET_IS_CHECK_SUCCESS,
        isCheckTotal: isCheckTotal,
      });
      yield put({
        type: actions.EDIT_TASK_SUCCESS,
        task,
      });
      yield resolve(task);
    } catch (e) {
      yield put({ type: actions.EDIT_TASK_ERROR });
      reject(e);
    }
  });
}

function* getTaskLogs() {
  yield takeEvery(actions.GET_TASK_TASKLOG_REQUEST, function* ({ payload }) {
    const { taskId, ...data } = payload;
    try {
      const { taskLogs, total } = yield call(taskService.fetchTaskLogs, {
        taskId,
        ...data,
      });
      yield put({
        type: actions.GET_TASK_TASKLOG_SUCCESS,
        taskLogs,
        taskLogsTotal: total,
      });
    } catch (error) {
      yield put({
        type: actions.GET_TASK_TASKLOG_ERROR,
      });
    }
  });
}

function* getSummaries() {
  yield takeEvery(actions.GET_SUMMARIES_REQUEST, function* ({ payload }) {
    const { taskId, ...data } = payload;
    try {
      const { userTaskSummaries, total } = yield call(taskService.fetchSummaries, {
        taskId,
        ...data,
      });
      yield put({
        type: actions.GET_SUMMARIES_SUCCESS,
        summaries: userTaskSummaries,
        summariesTotal: total,
      });
    } catch (error) {
      yield put({
        type: actions.GET_SUMMARIES_ERROR,
      });
    }
  });
}

export function* getComments() {
  yield takeLatest(actions.GET_COMMENTS_REQUEST, function* ({ payload }) {
    const { taskId, resolve, reject, ...data } = payload;
    try {
      const response = yield call(taskService.getComments, {
        taskId: +taskId,
        data,
      });
      const listComments = reverse(get(response, "comments", []));
      const comments = listComments.map((comment) => {
        let obj = Object.assign({}, comment);
        obj.editComment = true;
        obj.url = comment.attachId? downloadService.buildUrlDownload({
              taskAttachId: comment.attachId,
              taskId: comment.taskId,
            })
            : undefined
        return obj;
      });
      const totalComment = +get(response, "total", 0);
      yield put({ type: actions.GET_COMMENTS_SUCCESS, comments, totalComment });
      yield resolve(comments);
    } catch (e) {
      yield put({ type: actions.GET_COMMENTS_ERROR });
      reject();
    }
  });
}

export function* createComment() {
  yield takeLatest(actions.CREATE_COMMENT_REQUEST, function* ({ payload }) {
    const { accountId, data, resolve, reject } = payload;
    try {
      const comment = yield call(taskService.createComment, data);
      if (comment.personInCharge !== null && comment.personInCharge !== undefined) {
        comment.personInCharge = comment.personInCharge.split(",").map(Number);
      } else {
        comment.personInCharge = []
      }

      const task = pick(comment, [
          "startDate",
          "dueDate",
          "deliveryDate",
          "personInCharge",
          "estPoint",
          "estTime",
          "status",
          "assigneeId",
        ]);
      if (accountId !== null && accountId !== undefined) {
        const isCheckTotal = yield call(notifyService.getIsCheckNotification,  accountId );
        yield put({
          type: notifyActions.GET_IS_CHECK_SUCCESS,
          isCheckTotal: isCheckTotal,
        });
      }
      yield put({ type: actions.CREATE_COMMENT_SUCCESS, comment, task });
      yield resolve(comment);
    } catch (e) {
      yield put({ type: actions.CREATE_COMMENT_ERROR });
      yield reject(e);
    }
  });
}

function* getTaskChildren() {
  yield takeEvery(actions.GET_TASKS_CHILDREN_REQUEST, function* ({ payload }) {
    const { ...data } = payload;
    try {
      const { tasks, total } = yield call(taskService.getTaskChildren, { ...data });
      for (let i = 0; i < tasks.length; i++) {
        if (tasks[i].executedTeam !== null && tasks[i].executedTeam !== undefined) {
          tasks[i].executedTeam = tasks[i].executedTeam.split(",")
        } else {
          tasks[i].executedTeam = []
        }
      }
      yield put({
        type: actions.GET_TASKS_CHILDREN_SUCCESS,
        tasks,
        total,
      });
    } catch (error) {
      yield put({ type: actions.GET_TASKS_CHILDREN_ERROR, error: error.response });
    }
  });
}

export function* fetchTaskLogsLatest() {
  yield takeLatest(actions.FETCH_TASK_TASKLOG_LATEST_REQUEST, function* ({
    payload,
  }) {
    const { taskId, resolve, reject } = payload;
    try {
      const response = yield call(taskService.fetchTaskLogsLatest, {
        taskId: +taskId,
      });
      yield put({ type: actions.FETCH_TASK_TASKLOG_LATEST_SUCCESS, response });
      yield resolve(response);
    } catch (e) {
      yield put({ type: actions.FETCH_TASK_TASKLOG_LATEST_ERROR });
      reject(e);
    }
  });
}

export function* executeTaskStopWatch() {
  yield takeLatest(actions.EXECUTE_TASK_STOP_WATCH, function* ({ payload }) {
    const { taskId, action, type, resolve, reject } = payload;
    try {
      const response = yield call(taskService.executeStopWatch, {
        taskId: +taskId,
        action,
        type,
      });
      yield resolve(response);
      if (action === TASK_STOP_WATCH.ACTIONS.STOP) {
        yield put({
          type: actions.GET_TASK_TOTAL_TIMES_REQUEST,
          payload: {
            taskId: +taskId,
          },
        });
      }
    } catch (e) {
      reject(e);
    }
  });
}

export function* getTaskTotalTimes() {
  yield takeLatest(actions.GET_TASK_TOTAL_TIMES_REQUEST, function* ({ payload }) {
    const { taskId, resolve, reject } = payload;
    try {
      const response = yield call(taskService.getTaskTotalTimes, {
        taskId: +taskId,
      });
      yield put({
        type: actions.GET_TASK_TOTAL_TIMES_SUCCESS,
        sumConfirmTime: response.sum_confirm_time,
        sumExecuteTime: response.sum_execute_time,
      });
      if (resolve) resolve({});
    } catch (e) {
      if (reject) resolve(e);
    }
  });
}

function* editTaskLog() {
  yield takeEvery(actions.EDIT_TASKLOG_REQUEST, function* ({ payload }) {
    const { taskId, taskLogId, datetime, resolve, reject } = payload;
    try {
      yield call(taskService.editTaskLog, { taskId, taskLogId, datetime });
      yield put({
        type: actions.EDIT_TASKLOG_SUCCESS,
      });
      yield resolve({});
    } catch (error) {
      yield put({
        type: actions.EDIT_TASKLOG_ERROR,
      });
      yield reject({});
    }
  });
}

function* editComment() {
  yield takeEvery(actions.EDIT_COMMENT_REQUEST, function* ({ payload }) {
    const { commentId, commentText, type, size, sortBy, sortByType, taskId, resolve, reject } = payload;
    try {
      const response = yield call(taskService.editComment, { commentId, commentText, taskId, type, size, sortBy, sortByType, resolve, reject });
      const listComments = reverse(get(response, "comments", []));
      const comments = listComments.map((comment) => {
        let obj = Object.assign({}, comment);
        obj.editComment = true;
        return obj;
      });
      yield put({
        type: actions.EDIT_COMMENT_SUCCESS,
        comments: comments,
      });
      yield resolve({});
    } catch (error) {
      yield put({
        type: actions.EDIT_COMMENT_ERROR,
      });
      yield reject({});
    }
  });
}

export function* getListUser() {
  yield takeEvery(actions.GET_LIST_USER, function* ({ payload }) {
    const { values  } = payload;
    try {
      const userAssigneeList = yield call(taskService.getUserAssigneeList, values);
      yield put({
        type: actions.GET_LIST_USER_SUCCESS,
        userAssigneeList: userAssigneeList.accounts,
      });
    } catch (e) {
      yield put({ type: actions.GET_LIST_USER_ERROR });
    }
  });
}

export function* getMemberList() {
  yield takeLatest(actions.GET_MEMBER_LIST_REQUEST, function* ({ payload }) {
    const { values } = payload;
    try {
      const [memberList] = yield all([
        call(accountService.getMemberList, values),
      ]);
      yield put({
        type: actions.GET_MEMBER_LIST_SUCCESS,
        memberList: memberList?.accounts || [],
      });
    } catch (e) {
      yield put({ type: actions.GET_MEMBER_LIST_ERROR });
    }
  });
}

export function* getOtaList() {
  yield takeLatest(actions.GET_OTA_LIST_REQUEST, function* ({ payload }) {
    try {
      const [otaList] = yield all([
        call(otaService.getAllOtas),
      ]);
      yield put({
        type: actions.GET_OTA_LIST_SUCCESS,
        otaList: otaList?.rows || [],
      });
    } catch (e) {
      yield put({ type: actions.GET_OTA_LIST_ERROR });
    }
  });
}

export function* getApiOtaList() {
  yield takeLatest(actions.GET_API_OTA_LIST_REQUEST, function* ({ payload }) {
    try {
      const [otaList] = yield all([
        call(otaService.getAllApiOtas),
      ]);
      yield put({
        type: actions.GET_API_OTA_LIST_SUCCESS,
        apiOtaList: otaList?.rows || [],
      });
    } catch (e) {
      yield put({ type: actions.GET_API_OTA_LIST_ERROR });
    }
  });
}

export function* getContentList() {
  yield takeLatest(actions.GET_CONTENT_LIST_REQUEST, function* ({ payload }) {
    const { otaId, executedTeam, type, resolve, reject } = payload;
    try {
      const [contentList] = yield all([
        call(contentService.getContentList, { otaId, executedTeam, type }),
      ]);
      yield put({
        type: actions.GET_CONTENT_LIST_SUCCESS,
        contentList: contentList?.rows || [],
      });
      yield resolve({contentList: contentList?.rows});
    } catch (e) {
      yield put({ type: actions.GET_CONTENT_LIST_ERROR });
      yield reject({contentList: []});
    }
  });
}

function* getTaskContents() {
  yield takeEvery(actions.GET_TASK_CONTENT_REQUEST, function* ({ payload }) {
    const { taskId, createdAt, resolve, reject } = payload;
    try {
      const { taskContentExecuteList, taskContentConfirmList } = yield call(taskService.fetchTaskContents, {
        taskId,
        createdAt,
      });
      let taskContents = {
        EXECUTE: taskContentExecuteList,
        CONFIRM: taskContentConfirmList,
      }
      yield put({
        type: actions.GET_TASK_CONTENT_SUCCESS,
        taskContents
      });
      yield resolve({taskContents: taskContents});
    } catch (error) {
      yield put({
        type: actions.GET_TASK_CONTENT_ERROR,
      });
      yield reject({taskContents: {
        EXECUTE: [{contentList: []}],
        CONFIRM: [{contentList: []}],
        }});
    }
  });
}

export function* getTeamContentList() {
  yield takeLatest(actions.GET_TEAM_CONTENT_LIST_REQUEST, function* ({ payload }) {
    const { otaId, executedTeam, type, resolve, reject } = payload;
    try {
      const [teamContents] = yield all([
        call(contentService.getContentList, { otaId, executedTeam, type }),
      ]);
      yield put({
        type: actions.GET_TEAM_CONTENT_LIST_SUCCESS,
      });
      resolve(teamContents?.rows || [])
    } catch (e) {
      yield put({ type: actions.GET_TEAM_CONTENT_LIST_ERROR });
      reject([])
    }
  });
}

export function* getTeamExecuteContentList() {
  yield takeLatest(actions.GET_TEAM_EXECUTE_CONTENT_LIST_REQUEST, function* ({ payload }) {
    const { executedTeam, type } = payload;
    try {
      let otaId = null
      const [teamExecuteContents] = yield all([
        call(contentService.getContentList, { otaId, executedTeam, type }),
      ]);
      yield put({
        type: actions.GET_TEAM_EXECUTE_CONTENT_LIST_SUCCESS,
        teamExecuteContents: teamExecuteContents?.rows || [],
      });
    } catch (e) {
      yield put({ type: actions.GET_TEAM_EXECUTE_CONTENT_LIST_ERROR });
    }
  });
}

export function* getTeamConfirmContentList() {
  yield takeLatest(actions.GET_TEAM_CONFIRM_CONTENT_LIST_REQUEST, function* ({ payload }) {
    const { executedTeam, type } = payload;
    try {
      let otaId = null
      const [teamConfirmContents] = yield all([
        call(contentService.getContentList, { otaId, executedTeam, type }),
      ]);
      yield put({
        type: actions.GET_TEAM_CONFIRM_CONTENT_LIST_SUCCESS,
        teamConfirmContents: teamConfirmContents?.rows || [],
      });
    } catch (e) {
      yield put({ type: actions.GET_TEAM_CONFIRM_CONTENT_LIST_ERROR });
    }
  });
}

export function* fetchTaskContentLogsLatest() {
  yield takeLatest(actions.FETCH_TASK_CONTENT_TASKLOG_LATEST_REQUEST, function* (
      {
        payload,
      }) {
    const {taskId, resolve, reject} = payload;
    try {
      const response = yield call(taskService.fetchTaskContentLogsLatest, {
        taskId: +taskId,
      });
      yield put({type: actions.FETCH_TASK_CONTENT_TASKLOG_LATEST_SUCCESS, response});
      yield resolve(response);
    } catch (e) {
      yield put({type: actions.FETCH_TASK_CONTENT_TASKLOG_LATEST_ERROR});
      reject(e);
    }
  });
}

export function* getTaskContentTotalTimes() {
  yield takeLatest(actions.GET_TASK_CONTENT_TOTAL_TIMES_REQUEST, function* ({ payload }) {
    const { taskId, resolve, reject } = payload;
    try {
      const response = yield call(taskService.getTaskContentTotalTimes, {
        taskId: +taskId,
      });
      yield put({
        type: actions.GET_TASK_TOTAL_TIMES_SUCCESS
      });
      if (resolve) resolve(response);
    } catch (e) {
      if (reject) resolve(e);
    }
  });
}

export function* executeTaskContentStopWatch() {
  yield takeLatest(actions.EXECUTE_TASK_CONTENT_STOP_WATCH, function* ({ payload }) {
    const { taskId, taskContentId, accountId, action, type, resolve, reject } = payload;
    try {
      const response = yield call(taskService.executeTaskContentStopWatch, {
        taskId: +taskId,
        action,
        type,
        taskContentId,
        accountId,
      });
      if (response.task.executedTeam !== null && response.task.executedTeam !== undefined) {
        response.task.executedTeam = response.task.executedTeam.split(",")
      } else {
        response.task.executedTeam = []
      }

      if (response.task.personInCharge !== null && response.task.personInCharge !== undefined) {
        response.task.personInCharge = response.task.personInCharge.split(",").map(Number);
      } else {
        response.task.personInCharge = []
      }
      yield put({
        type: actions.EXECUTE_TASK_CONTENT_STOP_WATCH_SUCCESS,
        task: response.task,
        comment: response.comment
      });
      yield resolve(response);
    } catch (e) {
      reject(e);
    }
  });
}

function* getManualFile() {
  yield takeEvery(actions.TASK_GET_MANUAL_FILE_REQUEST, function* ({payload}) {
    try {
      let { otaId, contentId, resolve } = payload;
      const manualFile = yield call(helpService.getManualFile, { otaId, contentId });
      yield put({
        type: actions.TASK_GET_MANUAL_FILE_SUCCESS,
        manualFile,
      });
      resolve(manualFile)
    } catch (error) {
      yield put({
        type: actions.TASK_GET_MANUAL_FILE_ERROR,
        error: error.response,
      });
    }
  });
}

export function* editTaskAssign() {
  yield takeLatest(actions.EDIT_TASK_ASSIGN_REQUEST, function* ({ payload }) {
    const { taskId, accountId, data, resolve, reject } = payload;
    try {
      const task = yield call(taskService.editTaskAssign, {
        taskId: +taskId,
        data,
      });
      yield put({
        type: actions.EDIT_TASK_ASSIGN_SUCCESS,
        task,
      });
      yield resolve(task);
    } catch (e) {
      yield put({ type: actions.EDIT_TASK_ASSIGN_ERROR });
      reject();
    }
  });
}

function* taskAttachUploadBySubCategory() {
  yield takeEvery(actions.TASK_ATTACH_UPLOAD_BY_SUB_CATEGORY_REQUEST, function* ({ payload }) {
    const {
      task,
      subCategoryId,
      resolve,
      reject,
    } = payload;
    try {
      let taskId = Object.keys(task).length > 0 ? task.taskId : 0;
      const response = yield call(taskService.taskAttachUploadBySubCategory, {
        taskId,
        subCategoryId,
        resolve,
        reject,
      });
      yield put({
        type: actions.TASK_ATTACH_UPLOAD_BY_SUB_CATEGORY_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.TASK_ATTACH_UPLOAD_BY_SUB_CATEGORY_ERROR,
        error: e.message,
      });
    }
  });
}
export function* updateTaskMemo() {
  yield takeLatest(actions.UPDATE_TASK_MEMO_REQUEST, function* ({ payload }) {
    const { taskId, data, resolve, reject } = payload;
    try {
      yield call(taskService.updateTaskMemo, {
        taskId: +taskId,
        data,
      });
      yield put({
        type: actions.UPDATE_TASK_MEMO_SUCCESS,
      });
      yield resolve();
    } catch (e) {
      yield put({ type: actions.UPDATE_TASK_MEMO_ERROR });
      reject(e);
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(getTaskDetail),
    fork(getTaskMetadata),
    fork(createTask),
    fork(editTask),
    fork(getCommentUserNotifyRequest),
    fork(getTaskLogs),
    fork(getSummaries),
    fork(getComments),
    fork(createComment),
    fork(getTaskChildren),
    fork(fetchTaskLogsLatest),
    fork(executeTaskStopWatch),
    fork(getTaskTotalTimes),
    fork(editTaskLog),
    fork(editComment),
    fork(getListUser),
    fork(getMemberList),
    fork(getOtaList),
    fork(getApiOtaList),
    fork(getContentList),
    fork(getTaskContents),
    fork(getTeamExecuteContentList),
    fork(getTeamConfirmContentList),
    fork(fetchTaskContentLogsLatest),
    fork(getTaskContentTotalTimes),
    fork(executeTaskContentStopWatch),
    fork(getManualFile),
    fork(editTaskAssign),
    fork(getTeamContentList),
    fork(taskAttachUploadBySubCategory),
    fork(updateTaskMemo),
  ]);
}
