import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";
import BaseService from "./base.service";

export default class ContentService extends BaseService {
  getContentList = (values) => {
    const { otaId, executedTeam, type } = values;
    return this.axios.get(`${API_ENDPOINTS.CONTENT_LIST}`, {
      params: {
        otaId: otaId,
        executedTeam: executedTeam,
        type,
      },
    });
  };

  createContent = (data) => {
    return this.axios.post(API_ENDPOINTS.CREATE_CONTENT, data);
  }
}
