import { all, takeLatest, takeEvery, put, fork, call } from "redux-saga/effects";

import { apiOptionValuesService } from "@iso/services";
import actions from "@iso/redux/apiOptionValues/actions";

function* getApiOptionValues() {
  yield takeEvery(actions.GET_API_OPTION_VALUES_REQUEST, function* ({ payload }) {
    const {
      otaIds,
      type
    } = payload;
    try {
      const response = yield call(apiOptionValuesService.getDataOptionWithCondition, {
        otaIds,
        type
      });
      yield put({
        type: actions.GET_API_OPTION_VALUES_SUCCESS,
        apiOptionValues: response ? response : [],
      });
    } catch (e) {
      yield put({
        type: actions.GET_API_OPTION_VALUES_ERROR,
        error: e.message,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
      fork(getApiOptionValues),
  ]);
}
