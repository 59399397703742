import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";

import BaseService from "./base.service";

export default class ApiCrawlPlanService extends BaseService {
    getListCrawlPlan = ({ ...data }) => {
        return this.axios.post(API_ENDPOINTS.CRAWL_PLAN_SEARCH, {
            ...data,
        });
    };

    crawlPlan = ({ ...data }) => {
        return this.axios.post(API_ENDPOINTS.CRAWL_PLAN, {
            ...data,
        });
    };
}