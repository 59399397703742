import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";
import paginationConfig from "@iso/config/pagination.config";

import BaseService from "./base.service";

export default class NotifyService extends BaseService {
  getNotify = ({ ...params }) => {
    return this.axios.get(API_ENDPOINTS.NOTIFICATION, {
      params: {
        ...params,
        size: paginationConfig.pageSize,
        sortBy: "created_date",
        sortByType: 1,
      },
    });
  };

  updateStatus = ({ taskId, status }) => {
    return this.axios.post(
      API_ENDPOINTS.TASK_EDIT_STATUS.replace("{task_id}", taskId),
      {
        status,
      }
    );
  };

  getIsCheckNotification = ( accountId ) => {
    return this.axios.get(`${API_ENDPOINTS.CHECK_NOTIFICATION}/${accountId}`);
  };

  setIsCheckNotification = ( accountId ) => {
    return this.axios.get(`${API_ENDPOINTS.SET_IS_CHECK_NOTIFICATION}/${accountId}`);
  };

  setSeenNotifi = ({ notificationId }) => {
    return this.axios.get(`${API_ENDPOINTS.SETSEENNOTIFI}/${notificationId}`);
  };
}
