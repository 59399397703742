import actions from "@iso/redux/apiOptionValues/actions";

const initialState = {
  apiOptionValues: [],
  loading: true,
};

export default function ApiOptionValuesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_API_OPTION_VALUES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_API_OPTION_VALUES_SUCCESS:
      return {
        ...state,
        apiOptionValues: action.apiOptionValues,
        loading: false,
      };
    case actions.GET_API_OPTION_VALUES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
