import { all, takeLatest,takeEvery, put, fork, call } from "redux-saga/effects";

import {apiImageService} from "@iso/services";
import actions from "@iso/redux/apiImage/actions";


function* createApiImage() {
  yield takeLatest(actions.ADD_IMAGE_REQUEST, function* ({ payload }) {
    const { data, resolve, reject } = payload;
    try {
      const apiImage = yield call(apiImageService.createApiImage, { ...data });
      yield put({
        type: actions.ADD_IMAGE_SUCCESS,
        apiImage,
      });
      yield resolve(apiImage);
    } catch (e) {
      yield put({ type: actions.ADD_IMAGE_ERROR });
      reject(e);
    }
  });
}

function* getApiImage() {
  yield takeEvery(actions.SEARCH_IMAGE_REQUEST, function* ({ payload }) {
    const {
      storeId,
      imageName,
      otaIds,
      apiImageCategoryIds,
      size,
      page,
      resolve,
      reject,
    } = payload;
    try {
      const response = yield call(apiImageService.getApiImage, {
        storeId,
        imageName,
        otaIds,
        apiImageCategoryIds,
        size,
        page,
        resolve,
        reject,
      });
      yield put({
        type: actions.SEARCH_IMAGE_SUCCESS,
        apiImageOtas: response ? response.apiImageOtas : [],
        total: response ? response.total : 0
      });
      yield resolve(response.apiImageOtas);
    } catch (e) {
      yield put({
        type: actions.SEARCH_IMAGE_ERROR,
        error: e.message,
      });
    }
  });
}

function* deleteApiImage() {
  yield takeEvery(actions.DELETE_IMAGE_REQUEST, function* ({ payload }) {
    const { apiImageOtaId, resolve, reject } = payload;
    try {
      const response = yield call(apiImageService.deleteApiImage, apiImageOtaId);
      yield put({
        type: actions.DELETE_IMAGE_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.DELETE_IMAGE_ERROR,
        error: e,
      });
      reject(e);
    }
  });
}

function* getApiImageByStoreAndOta() {
  yield takeEvery(actions.IMAGE_BY_STORE_AND_OTA_REQUEST, function* ({ payload }) {
    const {
      storeId,
      otaIds,
      resolve
    } = payload;
    try {
      const response = yield call(apiImageService.getApiImageByStoreAndOta, {
        storeId,
        otaIds
      });

      yield put({
        type: actions.IMAGE_BY_STORE_AND_OTA_SUCCESS,
        apiImageOtas: response ? response : []
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.IMAGE_BY_STORE_AND_OTA_ERROR,
        error: e.message,
      });
    }
  });
}

function* getApiImageStoreAndOta() {
  yield takeEvery(actions.IMAGE_STORE_AND_OTA_REQUEST, function* ({ payload }) {
    const {
      storeId,
      otaId,
      resolve
    } = payload;
    try {
      const response = yield call(apiImageService.getApiImageStoreAndOta, {
        storeId,
        otaId
      });

      yield put({
        type: actions.IMAGE_STORE_AND_OTA_SUCCESS,
        apiImageOtas: response ? response : []
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.IMAGE_STORE_AND_OTA_ERROR,
        error: e.message,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
      fork(createApiImage),
      fork(getApiImage),
      fork(getApiImageByStoreAndOta),
      fork(deleteApiImage),
      fork(getApiImageStoreAndOta),
  ]);
}
