import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";
import BaseService from "./base.service";
import {findTemplateByCategoryId} from "@iso/redux/template/saga";

export default class TemplateService extends BaseService {
  findTemplateByCategoryId = (data) => {
    return this.axios.get(API_ENDPOINTS.GET_TEMPLATE.replace("{categoryId}", data.categoryId));
  };

  findTemplateBySubCategoryId = (data) => {
    return this.axios.get(API_ENDPOINTS.GET_TEMPLATE_BY_SUB_CATEGORY.replace("{subCategoryId}", data.subCategoryId));
  };
}
