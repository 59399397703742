import { all, takeLatest, takeEvery, put, fork, call } from "redux-saga/effects";

import {apiCancelPolicyService, apiPlanOtaService} from "@iso/services";
import actions from "@iso/redux/apiCancelPolicy/actions";

function* getListCancelPolicy() {
  yield takeEvery(actions.GET_LIST_CANCEL_POLICY_REQUEST, function* ({ payload }) {
    const {
      storeId,
      otaId
    } = payload;
    try {
      const response = yield call(apiCancelPolicyService.getListCancelPolicy, {
        storeId,
        otaId
      });
      yield put({
        type: actions.GET_LIST_CANCEL_POLICY_SUCCESS,
        listCancelPolicy: response.listCancelPolicy ? response.listCancelPolicy : [],
        statusCrawlCancelPolicy: response.statusCrawlCancelPolicy ? response.statusCrawlCancelPolicy : null,
      });
    } catch (e) {
      yield put({
        type: actions.GET_LIST_CANCEL_POLICY_ERROR,
        error: e.message,
      });
    }
  });
}

export function* crawlDataCancelPolicy() {
  yield takeLatest(actions.CRAWL_DATA_CANCEL_POLICY_REQUEST, function* ({payload}) {
    const { data, resolve, reject } = payload;
    try {
      const response = yield call(apiCancelPolicyService.crawlDataCancelPolicy, {
        ...data
      });
      yield put({
        type: actions.CRAWL_DATA_CANCEL_POLICY_SUCCESS,
      });
      yield resolve(response)
    } catch (e) {
      yield put({
        type: actions.CRAWL_DATA_CANCEL_POLICY_ERROR,
      });
      yield reject(e)
    }
  })
}

export default function* rootSaga() {
  yield all([
      fork(getListCancelPolicy),
      fork(crawlDataCancelPolicy),
  ]);
}
