import actions from "./actions";

const initialState = {
  error: null,
  loading: false,
  crawler: {},
};

export default function crawlPlanSaleReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CRAWL_SALE_STATISTIC_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actions.CRAWL_SALE_STATISTIC_SUCCESS:
      return {
        ...state,
        crawler: action.crawler,
        loading: false
      };
    case actions.CRAWL_SALE_STATISTIC_ERROR:
      return {
        ...state,
        loading: false
      };
    case actions.GET_CRAWL_SALE_STATISTIC_REQUEST:
      return {
        ...state,
        loading: true,
        crawler: {},
      };
    case actions.GET_CRAWL_SALE_STATISTIC_SUCCESS:
      return {
        ...state,
        loading: false,
        crawler: action.crawler,
      };
    case actions.GET_CRAWL_SALE_STATISTIC_ERROR:
      return {
        ...state,
        loading: false,
        crawler: {},
      };
    default:
      return state;
  }
}
