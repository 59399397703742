import actions from "@iso/redux/apiCrawlPlan/actions";

const initialState = {
  apiCrawlPlans: [],
  plan: {},
  total: 0,
  loading: false,
  crawlLoading: false,
  listUser: [],
};

export default function crawlPlan(state = initialState, action) {
  switch (action.type) {
    case actions.GET_CRAWL_PLAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_CRAWL_PLAN_SUCCESS:
      return {
        ...state,
        apiCrawlPlans: action.apiCrawlPlans,
        total: action.total,
        loading: false,
      };
    case actions.GET_CRAWL_PLAN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.CRAWL_PLAN_REQUEST:
      return {
        ...state,
        crawlLoading: true,
      };
    case actions.CRAWL_PLAN_SUCCESS:
      return {
        ...state,
        crawlLoading: false,
      };
    case actions.CRAWL_PLAN_ERROR:
      return {
        ...state,
        crawlLoading: false,
        error: action.error,
      };
    case actions.GET_LIST_USER_REQUEST:
      return {
        ...state,
      };
    case actions.GET_LIST_USER_SUCCESS:
      return {
        ...state,
        listUser: action.listUser,
      };
    case actions.GET_LIST_USER_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
