const actions = {
  CRAWL_PRINCE_ACHIEVEMENT_REQUEST: "CRAWL_PRINCE_ACHIEVEMENT_REQUEST",
  CRAWL_PRINCE_ACHIEVEMENT_SUCCESS: "CRAWL_PRINCE_ACHIEVEMENT_SUCCESS",
  CRAWL_PRINCE_ACHIEVEMENT_ERROR: "CRAWL_PRINCE_ACHIEVEMENT_ERROR",

  GET_CRAWL_PRINCE_ACHIEVEMENT_REQUEST: "GET_CRAWL_PRINCE_ACHIEVEMENT_REQUEST",
  GET_CRAWL_PRINCE_ACHIEVEMENT_SUCCESS: "GET_CRAWL_PRINCE_ACHIEVEMENT_SUCCESS",
  GET_CRAWL_PRINCE_ACHIEVEMENT_ERROR: "GET_CRAWL_PRINCE_ACHIEVEMENT_ERROR",

  DOWNLOAD_CRAWL_PRINCE_ACHIEVEMENT_REQUEST: "DOWNLOAD_CRAWL_PRINCE_ACHIEVEMENT_REQUEST",
  DOWNLOAD_CRAWL_PRINCE_ACHIEVEMENT_SUCCESS: "DOWNLOAD_CRAWL_PRINCE_ACHIEVEMENT_SUCCESS",
  DOWNLOAD_CRAWL_PRINCE_ACHIEVEMENT_ERROR: "DOWNLOAD_CRAWL_PRINCE_ACHIEVEMENT_ERROR",

  crawlPrinceAchievement: (payload) => ({
    type: actions.CRAWL_PRINCE_ACHIEVEMENT_REQUEST,
    payload,
  }),

  getCrawlPrinceAchievement: (payload) => ({
    type: actions.GET_CRAWL_PRINCE_ACHIEVEMENT_REQUEST,
    payload,
  }),

  downloadCrawlPrinceAchievement: (payload) => ({
    type: actions.DOWNLOAD_CRAWL_PRINCE_ACHIEVEMENT_REQUEST,
    payload,
  }),
};
export default actions;
