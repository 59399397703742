import BaseService from "./base.service";
import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";

export default class aggregateService extends BaseService {
    aggregateRanking = () => {
        return this.axios.get(API_ENDPOINTS.HELP_AGGREGATE_RANKING_HOTEL);
    };
    downloadRanking = () => {
        return this.axios.get(API_ENDPOINTS.HELP_DOWNLOAD_RANKING_REPORT);
    };
}
