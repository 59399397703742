const actions = {
  CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST: "CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST",
  CRAWL_VISITOR_TO_HENNA_HOTEL_SUCCESS: "CRAWL_VISITOR_TO_HENNA_HOTEL_SUCCESS",
  CRAWL_VISITOR_TO_HENNA_HOTEL_ERROR: "CRAWL_VISITOR_TO_HENNA_HOTEL_ERROR",

  GET_CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST: "GET_CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST",
  GET_CRAWL_VISITOR_TO_HENNA_HOTEL_SUCCESS: "GET_CRAWL_VISITOR_TO_HENNA_HOTEL_SUCCESS",
  GET_CRAWL_VISITOR_TO_HENNA_HOTEL_ERROR: "GET_CRAWL_VISITOR_TO_HENNA_HOTEL_ERROR",

  DOWNLOAD_CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST: "DOWNLOAD_CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST",
  DOWNLOAD_CRAWL_VISITOR_TO_HENNA_HOTEL_SUCCESS: "DOWNLOAD_CRAWL_VISITOR_TO_HENNA_HOTEL_SUCCESS",
  DOWNLOAD_CRAWL_VISITOR_TO_HENNA_HOTEL_ERROR: "DOWNLOAD_CRAWL_VISITOR_TO_HENNA_HOTEL_ERROR",

  GET_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST: "GET_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST",
  GET_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_SUCCESS: "GET_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_SUCCESS",
  GET_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_ERROR: "GET_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_ERROR",

  SAVE_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST: "SAVE_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST",
  SAVE_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_SUCCESS: "SAVE_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_SUCCESS",
  SAVE_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_ERROR: "SAVE_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_ERROR",

  crawlVisitorToHennaHotel: (payload) => ({
    type: actions.CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST,
    payload,
  }),

  getCrawlVisitorToHennaHotel: (payload) => ({
    type: actions.GET_CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST,
    payload,
  }),

  downloadCrawlVisitorToHennaHotel: (payload) => ({
    type: actions.DOWNLOAD_CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST,
    payload,
  }),

  getDataAutoCrawl: (payload) => ({
    type: actions.GET_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST,
    payload,
  }),

  saveDataAutoCrawl: (payload) => ({
    type: actions.SAVE_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST,
    payload,
  }),
};
export default actions;
