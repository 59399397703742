import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";
import BaseService from "./base.service";

export default class CrawlVisitorToHennaHotelService extends BaseService {
  crawlVisitorToHennaHotel = (data) => {
    return this.axios.post(API_ENDPOINTS.CRAWL_VISITOR_TO_HENNA_HOTEL, data);
  }
  getCrawlVisitorToHennaHotel = () => {
    return this.axios.get(API_ENDPOINTS.GET_CRAWL_VISITOR_TO_HENNA_HOTEL);
  }
  getDataAutoCrawl = (data) => {
    return this.axios.post(API_ENDPOINTS.AUTO_CRAWL, data);
  }
  saveDataAutoCrawl = (data) => {
    return this.axios.post(API_ENDPOINTS.SAVE_DATA_AUTO_CRAWL, data);
  }
}
