const actions = {
  CRAWL_PLAN_SALE_REQUEST: "CRAWL_PLAN_SALE_REQUEST",
  CRAWL_PLAN_SALE_SUCCESS: "CRAWL_PLAN_SALE_SUCCESS",
  CRAWL_PLAN_SALE_ERROR: "CRAWL_PLAN_SALE_ERROR",

  GET_CRAWL_PLAN_SALE_REQUEST: "GET_CRAWL_PLAN_SALE_REQUEST",
  GET_CRAWL_PLAN_SALE_SUCCESS: "GET_CRAWL_PLAN_SALE_SUCCESS",
  GET_CRAWL_PLAN_SALE_ERROR: "GET_CRAWL_PLAN_SALE_ERROR",

  DOWNLOAD_CRAWL_PLAN_SALE_REQUEST: "DOWNLOAD_CRAWL_PLAN_SALE_REQUEST",
  DOWNLOAD_CRAWL_PLAN_SALE_SUCCESS: "DOWNLOAD_CRAWL_PLAN_SALE_SUCCESS",
  DOWNLOAD_CRAWL_PLAN_SALE_ERROR: "DOWNLOAD_CRAWL_PLAN_SALE_ERROR",

  crawlPlanSale: (payload) => ({
    type: actions.CRAWL_PLAN_SALE_REQUEST,
    payload,
  }),

  getCrawlPlanSale: (payload) => ({
    type: actions.GET_CRAWL_PLAN_SALE_REQUEST,
    payload,
  }),

  downloadCrawlPlanSale: (payload) => ({
    type: actions.DOWNLOAD_CRAWL_PLAN_SALE_REQUEST,
    payload,
  }),
};
export default actions;
