import actions from "@iso/redux/apiImageCategory/actions";

const initialState = {
  requesting: false,
  imageCategoryList: [],
  imageCategoryListPage: [],
  imageCategory: {},
  total: 0,
  loading: true,
};

export default function ApiImageCategoryReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_ALL_IMAGE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_ALL_IMAGE_CATEGORY_SUCCESS:
      return {
        ...state,
        imageCategoryList: action.imageCategoryList,
        total: action.total,
        loading: false,
      };
    case actions.GET_ALL_IMAGE_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.ADD_IMAGE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.ADD_IMAGE_CATEGORY_SUCCESS:
      return {
        ...state,
        apiImageCategory: action.apiImageCategory,
        loading: false,
      };
    case actions.ADD_IMAGE_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.GET_IMAGE_CATEGORY_REQUEST:
      return {
        ...state,
        requesting: true,
        imageCategory: {},
        error: null,
      };
    case actions.GET_IMAGE_CATEGORY_SUCCESS:
      return {
        ...state,
        requesting: false,
        imageCategory: action.imageCategory,
        error: null,
      };
    case actions.GET_IMAGE_CATEGORY_ERROR:
      return {
        ...state,
        requesting: true,
        loading: false,
      };
    case actions.GET_PAGE_IMAGE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_PAGE_IMAGE_CATEGORY_SUCCESS:
      return {
        ...state,
        imageCategoryListPage: action.imageCategoryListPage,
        total: action.total,
        loading: false,
      };
    case actions.GET_PAGE_IMAGE_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.DELETE_API_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.DELETE_API_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.DELETE_API_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
