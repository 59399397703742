const actions = {
  AMOUNT_RANK_GET_DATA_REQUEST: "AMOUNT_RANK_GET_DATA_REQUEST",
  AMOUNT_RANK_GET_DATA_SUCCESS: "AMOUNT_RANK_GET_DATA_SUCCESS",
  AMOUNT_RANK_GET_DATA_ERROR: "AMOUNT_RANK_GET_DATA_ERROR",

  AMOUNT_RANK_UPDATE_DATA_REQUEST: "AMOUNT_RANK_UPDATE_DATA_REQUEST",
  AMOUNT_RANK_UPDATE_DATA_SUCCESS: "AMOUNT_RANK_UPDATE_DATA_SUCCESS",
  AMOUNT_RANK_UPDATE_DATA_ERROR: "AMOUNT_RANK_UPDATE_DATA_ERROR",

  AMOUNT_GROUP_GET_DATA_REQUEST: "AMOUNT_GROUP_GET_DATA_REQUEST",
  AMOUNT_GROUP_GET_DATA_SUCCESS: "AMOUNT_GROUP_GET_DATA_SUCCESS",
  AMOUNT_GROUP_GET_DATA_ERROR: "AMOUNT_GROUP_GET_DATA_ERROR",

  AMOUNT_GROUP_EDIT_ITEM_REQUEST: "AMOUNT_GROUP_UPDATE_REQUEST",
  AMOUNT_GROUP_EDIT_ITEM_SUCCESS: "AMOUNT_GROUP_UPDATE_SUCCESS",
  AMOUNT_GROUP_EDIT_ITEM_ERROR: "AMOUNT_GROUP_UPDATE_ERROR",

  AMOUNT_GROUP_GET_ITEM_REQUEST: "AMOUNT_GROUP_GET_ITEM_REQUEST",
  AMOUNT_GROUP_GET_ITEM_SUCCESS: "AMOUNT_GROUP_GET_ITEM_SUCCESS",

  AMOUNT_GROUP_UPDATE_DATA_REQUEST: "AMOUNT_GROUP_UPDATE_DATA_REQUEST",
  AMOUNT_GROUP_UPDATE_DATA_SUCCESS: "AMOUNT_GROUP_UPDATE_DATA_SUCCESS",
  AMOUNT_GROUP_UPDATE_DATA_ERROR: "AMOUNT_GROUP_UPDATE_DATA_ERROR",

  AMOUNT_GROUP_ADD_EDIT_DATA_REQUEST: "AMOUNT_GROUP_ADD_EDIT_DATA_REQUEST",
  AMOUNT_GROUP_ADD_EDIT_DATA_SUCCESS: "AMOUNT_GROUP_ADD_EDIT_DATA_SUCCESS",
  AMOUNT_GROUP_ADD_EDIT_DATA_ERROR: "AMOUNT_GROUP_ADD_EDIT_DATA_ERROR",

  GET_TASK_TEMPLATES_REQUEST: "PLAN_AMOUNT/GET_TASK_TEMPLATES_REQUEST",
  GET_TASK_TEMPLATES_SUCCESS: "PLAN_AMOUNT/GET_TASK_TEMPLATES_SUCCESS",
  GET_TASK_TEMPLATES_ERROR: "PLAN_AMOUNT/GET_TASK_TEMPLATES_ERROR",

  GET_DEFAULT_PLANS_REQUEST: "PLAN_AMOUNT/GET_DEFAULT_PLANS_REQUEST",
  GET_DEFAULT_PLANS_SUCCESS: "PLAN_AMOUNT/GET_DEFAULT_PLANS_SUCCESS",
  GET_DEFAULT_PLANS_ERROR: "PLAN_AMOUNT/GET_DEFAULT_PLANS_ERROR",

  GET_DEFAULT_PLAN_REQUEST: "PLAN_AMOUNT/GET_DEFAULT_PLAN_REQUEST",
  GET_DEFAULT_PLAN_SUCCESS: "PLAN_AMOUNT/GET_DEFAULT_PLAN_SUCCESS",
  GET_DEFAULT_PLAN_ERROR: "PLAN_AMOUNT/GET_DEFAULT_PLAN_ERROR",

  CREATE_DEFAULT_PLAN_REQUEST: "PLAN_AMOUNT/CREATE_DEFAULT_PLAN_REQUEST",
  CREATE_DEFAULT_PLAN_SUCCESS: "PLAN_AMOUNT/CREATE_DEFAULT_PLAN_SUCCESS",
  CREATE_DEFAULT_PLAN_ERROR: "PLAN_AMOUNT/CREATE_DEFAULT_PLAN_ERROR",

  UPDATE_DEFAULT_PLAN_REQUEST: "PLAN_AMOUNT/UPDATE_DEFAULT_PLAN_REQUEST",
  UPDATE_DEFAULT_PLAN_SUCCESS: "PLAN_AMOUNT/UPDATE_DEFAULT_PLAN_SUCCESS",
  UPDATE_DEFAULT_PLAN_ERROR: "PLAN_AMOUNT/UPDATE_DEFAULT_PLAN_ERROR",

  REORDER_PLAN_REQUEST: "PLAN_AMOUNT/REORDER_PLAN_REQUEST",
  REORDER_PLAN_SUCCESS: "PLAN_AMOUNT/REORDER_PLAN_SUCCESS",
  REORDER_PLAN_ERROR: "PLAN_AMOUNT/REORDER_PLAN_ERROR",

  RESET_PLAN_AMOUNT: "RESET_PLAN_AMOUNT",

  FETCH_GROUP_PRICE_BY_STORES: "SELECT_GROUP_PRICE_BY_STORES",
  FETCH_GROUP_PRICE_BY_STORES_SUCCESS: "FETCH_GROUP_PRICE_BY_STORES_SUCCESS",
  FETCH_GROUP_PRICE_BY_STORES_ERROR: "FETCH_GROUP_PRICE_BY_STORES_ERROR",
  AMOUNT_GROUP_GET_DATA_STOREIDS_REQUEST: "AMOUNT_GROUP_GET_DATA_STOREIDS_REQUEST",
  SET_AMOUNT_GROUP_SELECTED: "SET_AMOUNT_GROUP_SELECTED",


  getAmountRankData: () => ({ type: actions.AMOUNT_RANK_GET_DATA_REQUEST }),

  updateAmountRank: (payload) => ({
    type: actions.AMOUNT_RANK_UPDATE_DATA_REQUEST,
    payload,
  }),

  getTaskTemplates: (payload) => ({
    type: actions.GET_TASK_TEMPLATES_REQUEST,
    payload,
  }),

  getDefaultPlans: (payload) => ({
    type: actions.GET_DEFAULT_PLANS_REQUEST,
    payload,
  }),

  getDefaultPlan: (payload) => ({
    type: actions.GET_DEFAULT_PLAN_REQUEST,
    payload,
  }),

  createDefaultPlan: (payload) => ({
    type: actions.CREATE_DEFAULT_PLAN_REQUEST,
    payload,
  }),

  updateDefaultPlan: (payload) => ({
    type: actions.UPDATE_DEFAULT_PLAN_REQUEST,
    payload,
  }),

  reOrderPlan: (payload) => ({
    type: actions.REORDER_PLAN_REQUEST,
    payload,
  }),

  getAmountGroupData: () => ({
    type: actions.AMOUNT_GROUP_GET_DATA_REQUEST,
  }),

  updateAmountGroups: (payload) => ({
    type: actions.AMOUNT_GROUP_UPDATE_DATA_REQUEST,
    payload,
  }),

  getAmountItemEdit: (payload) => ({
    type: actions.AMOUNT_GROUP_GET_ITEM_REQUEST,
    payload,
  }),

  addEditAmountGroup: (payload) => ({
    type: actions.AMOUNT_GROUP_ADD_EDIT_DATA_REQUEST,
    payload,
  }),

  getAmountGroupDataByStoreIds: (payload) => ({
    type: actions.AMOUNT_GROUP_GET_DATA_STOREIDS_REQUEST,
    payload,
  }),
  fetchGroupPrice: (payload) => ({
    type: actions.FETCH_GROUP_PRICE_BY_STORES,
    payload,
  }),
  setAmountGroupSelected: (amountGroupSelected) => ({
    type: actions.SET_AMOUNT_GROUP_SELECTED,
    amountGroupSelected,
  }),
  resetPlantAmount: () => ({
    type: actions.RESET_PLAN_AMOUNT,
  }),
};

export default actions;
