import { all, takeLatest, fork, call, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import { crawlVisitorToHennaHotelService } from "@iso/services";

export function* crawlVisitorToHennaHotel() {
  yield takeLatest(actions.CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST, function* ({ payload }) {
    const {
      periodStart,
      periodEnd,
      storeIds,
      resolve,
      reject
    } = payload;
    try {
      const response = yield call(crawlVisitorToHennaHotelService.crawlVisitorToHennaHotel, {
        periodStart,
        periodEnd,
        storeIds,
        resolve,
        reject
      });
      yield put({
        type: actions.CRAWL_VISITOR_TO_HENNA_HOTEL_SUCCESS,
        crawler: response,
      });
      yield resolve(response);
    } catch (e) {
      yield put({ type: actions.CRAWL_VISITOR_TO_HENNA_HOTEL_ERROR });
      reject(e);
    }
  });
}

export function* getCrawlVisitorToHennaHotel() {
  yield takeLatest(actions.GET_CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST, function* () {
    try {
      const report = yield call(crawlVisitorToHennaHotelService.getCrawlVisitorToHennaHotel);
      yield put({
        type: actions.GET_CRAWL_VISITOR_TO_HENNA_HOTEL_SUCCESS,
        crawler: report,
      });
    } catch (error) {
      yield put({
        type: actions.GET_CRAWL_VISITOR_TO_HENNA_HOTEL_ERROR,
        error: error.response,
      });
    }
  });
}

export function* downloadCrawlVisitorToHennaHotel() {
  yield takeLatest(actions.DOWNLOAD_CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST, function* () {
    try {
      const report = yield call(crawlVisitorToHennaHotelService.downloadFileCrawlVisitorToHennaHotel);
      yield put({
        type: actions.DOWNLOAD_CRAWL_VISITOR_TO_HENNA_HOTEL_SUCCESS,
        crawler: report,
      });
    } catch (error) {
      yield put({
        type: actions.DOWNLOAD_CRAWL_VISITOR_TO_HENNA_HOTEL_ERROR,
        error: error.response,
      });
    }
  });
}

export function* getDataAutoCrawl() {
  yield takeEvery(actions.GET_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST, function* ({ payload }) {
    const {
      type,
      resolve,
      reject
    } = payload;
    try {
      const response = yield call(crawlVisitorToHennaHotelService.getDataAutoCrawl, { type });
      yield put({
        type: actions.GET_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_SUCCESS,
        crawlerData: response,
      });
      resolve(response)
    } catch (error) {
      yield put({
        type: actions.GET_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_ERROR,
        error: error.response,
      });
      reject(error)
    }
  });
}

export function* saveDataAutoCrawl() {
  yield takeLatest(actions.SAVE_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST, function* ({ payload }) {
    const {
      type,
      storeIds,
      resolve,
      reject
    } = payload;
    try {
      const response = yield call(crawlVisitorToHennaHotelService.saveDataAutoCrawl, {
        type,
        storeIds,
        resolve,
        reject
      });
      yield put({
        type: actions.SAVE_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_SUCCESS,
        crawler: response
      });
      yield resolve(response);
    } catch (e) {
      yield put({ type: actions.SAVE_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_ERROR });
      reject(e);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(crawlVisitorToHennaHotel)]);
  yield all([fork(getCrawlVisitorToHennaHotel)]);
  yield all([fork(downloadCrawlVisitorToHennaHotel)]);
  yield all([fork(saveDataAutoCrawl)]);
  yield all([fork(getDataAutoCrawl)]);
}
