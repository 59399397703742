import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import _ from "lodash";

import { notifyService } from "@iso/services";

import actions from "./actions";

function* getNotify() {
  yield takeEvery(actions.GET_NOTIFY_REQUEST, function* ({ payload }) {
    const { page } = payload;
    try {
      const response = yield call(notifyService.getNotify, { page });
      yield put({
        type: actions.GET_NOTIFY_SUCCESS,
        notifications: response.notifications,
        total: response.total,
      });
    } catch (e) {
      yield put({
        type: actions.GET_NOTIFY_ERROR,
        error: e,
      });
    }
  });
}

function* updateStatus() {
  yield takeEvery(actions.UPDATE_STATUS_REQUEST, function* ({payload}) {
    const {taskId, status, page} = payload;
    try {
      yield call(notifyService.updateStatus, {taskId, status});
      const response = yield call(notifyService.getNotify, {page});
      yield put({
        type: actions.UPDATE_STATUS_SUCCESS,
        ..._.pick(response, ["notifications", "total"]),
      });
    } catch (error) {
      yield put({
        type: actions.UPDATE_STATUS_ERROR,
        error: error.response,
      });
    }
  });
}

  function* getIsCheckNotification() {
    yield takeEvery(actions.GET_IS_CHECK, function* ({ payload }) {
      try {
        const isCheckTotal = yield call(notifyService.getIsCheckNotification,  payload );
        yield put({
          type: actions.GET_IS_CHECK_SUCCESS,
          isCheckTotal: isCheckTotal,
        });
      } catch (e) {
        yield put({
          type: actions.GET_NOTIFY_ERROR,
          error: e,
        });
      }
    });
  }

  function* setIsCheckNotification() {
    yield takeEvery(actions.SET_IS_CHECK_NOTIFICATION, function* ( {payload} ) {
      try {
        const isCheckTotal = yield call(notifyService.setIsCheckNotification,  payload );
        yield put({
          type: actions.GET_IS_CHECK_SUCCESS,
          isCheckTotal: isCheckTotal,
        });
      } catch (e) {
        yield put({
          type: actions.GET_NOTIFY_ERROR,
          error: e,
        });
      }
    });
}

function* setSeenNotifi() {
  yield takeEvery(actions.SET_SEEN_NOTIFI, function* ({ payload }) {
    const { notificationId } = payload;
    try {
      yield call(notifyService.setSeenNotifi, { notificationId });
    } catch (error) {
      yield put({
        type: actions.SET_SEEN_NOTIFI_ERROR,
        error: error.response,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getNotify), fork(updateStatus), fork(getIsCheckNotification), fork(setIsCheckNotification), fork(setSeenNotifi)]);
}
