import { all, takeEvery, put, fork, call } from "redux-saga/effects";

import { selectApiRoomService } from "@iso/services";
import actions from "@iso/redux/selectApiRoom/actions";

function* fetchApiRooms() {
  yield takeEvery(actions.FETCH_API_ROOMS_REQUEST, function* ({ payload }) {
    try {
      let { storeId, otaId } = payload
      const response = yield call(selectApiRoomService.fetchApiRooms, {storeId, otaId});
      yield put({
        type: actions.FETCH_API_ROOMS_SUCCESS,
        apiRooms: response.apiRoomOtas,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_API_ROOMS_ERROR,
        error: error.response,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetchApiRooms)]);
}
