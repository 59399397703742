import {findTemplateByCategoryId} from "@iso/redux/template/saga";

const actions = {
    GET_TEMPLATE_REQUEST: "GET_TEMPLATE_REQUEST",
    GET_TEMPLATE_SUCCESS: "GET_TEMPLATE_SUCCESS",
    GET_TEMPLATE_ERROR: "GET_TEMPLATE_ERROR",

    findTemplateByCategoryId: (payload) => ({
        type: actions.GET_TEMPLATE_REQUEST,
        payload,
    }),
};

export default actions;
