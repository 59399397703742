import { all, takeLatest, fork, call, put } from "redux-saga/effects";
import actions from "./actions";
import { crawlSaleStatisticService} from "@iso/services";

export function* crawlSaleStatistic() {
  yield takeLatest(actions.CRAWL_SALE_STATISTIC_REQUEST, function* ({ payload }) {
    const {
      startAggregationPeriodDate,
      endAggregationPeriodDate,
      storeIds,
      keyword,
      resolve,
      reject
    } = payload;
    try {
      const response = yield call(crawlSaleStatisticService.crawlSaleStatistic, {
        startAggregationPeriodDate,
        endAggregationPeriodDate,
        storeIds,
        keyword,
        resolve,
        reject
      });
      yield put({
        type: actions.CRAWL_SALE_STATISTIC_SUCCESS,
        crawler: response,
      });
      yield resolve(response);
    } catch (e) {
      yield put({ type: actions.CRAWL_SALE_STATISTIC_ERROR });
      reject(e);
    }
  });
}

export function* getCrawlSaleStatistic() {
  yield takeLatest(actions.GET_CRAWL_SALE_STATISTIC_REQUEST, function* () {
    try {
      const report = yield call(crawlSaleStatisticService.getCrawlSaleStatistic);
      yield put({
        type: actions.GET_CRAWL_SALE_STATISTIC_SUCCESS,
        crawler: report,
      });
    } catch (error) {
      yield put({
        type: actions.GET_CRAWL_SALE_STATISTIC_ERROR,
        error: error.response,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(crawlSaleStatistic)]);
  yield all([fork(getCrawlSaleStatistic)]);
}
