const actions = {
  GET_LIST_USER: "GET_LIST_USER",
  GET_LIST_USER_SUCCESS: "GET_LIST_USER_SUCCESS",
  GET_LIST_USER_ERROR: "GET_LIST_USER_ERROR",

  GET_LIST_REQUEST_TIME: "GET_LIST_REQUEST_TIME",
  GET_LIST_REQUEST_TIME_SUCCESS: "GET_LIST_REQUEST_TIME_SUCCESS",
  GET_LIST_REQUEST_TIME_ERROR: "GET_LIST_REQUEST_TIME_ERROR",

  POST_REQUEST_TIME: "POST_REQUEST_TIME",
  POST_REQUEST_TIME_SUCCESS: "POST_REQUEST_TIME_SUCCESS",
  POST_REQUEST_TIME_ERROR: "POST_REQUEST_TIME_ERROR",

  DELETE_REQUEST_TIME: "DELETE_REQUEST_TIME",
  DELETE_REQUEST_TIME_SUCCESS: "DELETE_REQUEST_TIME_SUCCESS",
  DELETE_REQUEST_TIME_ERROR: "DELETE_REQUEST_TIME_ERROR",

  getListUser: (payload)=> ({
    type: actions.GET_LIST_USER,
    payload,
  }),
  
  getListRequestTime: (payload)=> ({
    type: actions.GET_LIST_REQUEST_TIME,
    payload,
  }),

  postResquestTime: (payload) => ({
    type: actions.POST_REQUEST_TIME,
    payload: payload,
  }),
  
  deleteRequestTime: (payload) => ({
    type: actions.DELETE_REQUEST_TIME,
    payload: payload,
  })
};

export default actions;
