import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";
import BaseService from "./base.service";

export default class CrawlPrinceAchievementService extends BaseService {
  crawlPrinceAchievement = (data) => {
    return this.axios.post(API_ENDPOINTS.CRAWL_PRINCE_ACHIEVEMENT, data);
  }
  downloadFileCrawlPrinceAchievement = () => {
    return this.axios.get(API_ENDPOINTS.CRAWL_PRINCE_ACHIEVEMENT_DOWNLOAD);
  }
  getCrawlPrinceAchievement = () => {
    return this.axios.get(API_ENDPOINTS.GET_CRAWL_PRINCE_ACHIEVEMENT);
  }
}
