import actions from "./actions";

const initialState = {
  error: null,
  loading: false,
  crawler: {},
  crawlerDataCron: {},
};

export default function crawlPrinceAchievementReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST:
      return {
        ...state,
        loading: true,
        crawler: {},
      };
    case actions.CRAWL_VISITOR_TO_HENNA_HOTEL_SUCCESS:
      return {
        ...state,
        loading: false,
        crawler: action.crawler,
      };
    case actions.CRAWL_VISITOR_TO_HENNA_HOTEL_ERROR:
      return {
        ...state,
        loading: false,
        crawler: {},
      };
    case actions.GET_CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST:
      return {
        ...state,
        crawler: {},
      };
    case actions.GET_CRAWL_VISITOR_TO_HENNA_HOTEL_SUCCESS:
      return {
        ...state,
        crawler: action.crawler,
      };
    case actions.GET_CRAWL_VISITOR_TO_HENNA_HOTEL_ERROR:
      return {
        ...state,
        crawler: {},
        error: action.error,
      };
    case actions.DOWNLOAD_CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST:
      return {
        ...state,
      };
    case actions.DOWNLOAD_CRAWL_VISITOR_TO_HENNA_HOTEL_SUCCESS:
      return {
        ...state,
        crawler: action.crawler,
      };
    case actions.DOWNLOAD_CRAWL_VISITOR_TO_HENNA_HOTEL_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actions.GET_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST:
      return {
        ...state,
      };
    case actions.GET_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_SUCCESS:
      return {
        ...state,
        crawlerDataCron: action.crawlerData
      };
    case actions.GET_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actions.SAVE_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_REQUEST:
      return {
        ...state,
      };
    case actions.SAVE_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_SUCCESS:
      return {
        ...state,
      };
    case actions.SAVE_DATA_AUTO_CRAWL_VISITOR_TO_HENNA_HOTEL_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
