import actions from "./actions";

const initialState = {
  template: {},
  loading: false,
};

export default function storeReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_TEMPLATE_REQUEST: {
      return {
        ...state,
        template: {},
        loading: true,
      };
    }
    case actions.GET_TEMPLATE_SUCCESS: {
      return {
        ...state,
        template: action.template,
        loading: false,
      };
    }
    case actions.GET_TEMPLATE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
