import actions from "@iso/redux/selectApiRoom/actions";

const initialState = {
  loading: true,
  loadingMetaData: true,
  show: false,
  error: null,
  errorMetaData: null,
  apiRooms: [],
  listApiRoomInIds: [],
};

export default function selectApiRoomReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_API_ROOMS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        apiRooms: [],
      };
    case actions.FETCH_API_ROOMS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        apiRooms: action.apiRooms,
      };
    case actions.FETCH_API_ROOMS_ERROR:
      return {
        ...state,
        loading: false,
        apiRooms: [],
        error: action.error,
      };
    case actions.TOGGLE_SELECT_API_ROOM:
      return {
        ...state,
        show: !state.show,
      };
    default:
      return state;
  }
}
