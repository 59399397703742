import actions from "./actions";

const initialState = {
  task: {},
  loading: false,
  requesting: false,
  requestingTypes: false,
  updateRequesting: false,
  requestTimes: [],
  error: null,
  total: 0,
};

export default function requestTimeReducer(state = initialState, action) {
  const { requestTimes, total, error } = action;
  switch (action.type) {
    case actions.GET_LIST_USER: {
      return {
        ...state,
        userAssigneeList: [],
      };
    }
    case actions.GET_LIST_USER_SUCCESS: {
      return {
        ...state,
        userAssigneeList: action.userAssigneeList,
      };
    }
    case actions.GET_LIST_USER_ERROR: {
      return {
        ...state,
      };
    }
    case actions.GET_LIST_REQUEST_TIME: {
      return {
        ...state,
        requestTimes: [],
        requesting: true,
        total: 0,
        error: null,
      };
    }
    case actions.GET_LIST_REQUEST_TIME_SUCCESS: {
      return {
        ...state,
        requestTimes: action.requestTimes,
        requesting: false,
        total: action.total,
        error: null,
      };
    }
    case actions.GET_LIST_REQUEST_TIME_ERROR: {
      return {
        ...state,
      };
    }
    case actions.POST_REQUEST_TIME: {
      return {
        ...state,
      };
    }
    case actions.POST_REQUEST_TIME_SUCCESS: {
      return {
        ...state,
      };
    }
    case actions.POST_REQUEST_TIME_ERROR: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}
