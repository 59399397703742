import { all, takeEvery, put, fork, call } from "redux-saga/effects";

import {
  totalTimeWorkService
} from "@iso/services";
import actions from "./actions";

function* getListTotalTimeWork() {
  yield takeEvery(actions.GET_LIST_TOTAL_TIME_WORK, function* ({ payload }) {
    const { ...data } = payload;
    try {
      const totalTimeWorks = yield call(totalTimeWorkService.fetchTotalTimeWorks, {
        ...data,
      });
      yield put({
        type: actions.GET_LIST_TOTAL_TIME_WORK_SUCCESS,
        totalTimeWorks: totalTimeWorks ? totalTimeWorks.rows : [],
        total: totalTimeWorks.total,
      });
    } catch (e) {
      yield put({ type: actions.GET_LIST_TOTAL_TIME_WORK_ERROR });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getListTotalTimeWork),
  ]);
}
