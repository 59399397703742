const actions = {
    GET_ALL_IMAGE_CATEGORY_REQUEST: "IMAGE_CATEGORY/GET_ALL_IMAGE_CATEGORY_REQUEST",
    GET_ALL_IMAGE_CATEGORY_SUCCESS: "IMAGE_CATEGORY/GET_ALL_IMAGE_CATEGORY_SUCCESS",
    GET_ALL_IMAGE_CATEGORY_ERROR: "IMAGE_CATEGORY/GET_ALL_IMAGE_CATEGORY_ERROR",
    ADD_IMAGE_CATEGORY_REQUEST: "IMAGE_CATEGORY/ADD_IMAGE_CATEGORY_REQUEST",
    ADD_IMAGE_CATEGORY_SUCCESS: "IMAGE_CATEGORY/ADD_IMAGE_CATEGORY_SUCCESS",
    ADD_IMAGE_CATEGORY_ERROR: "IMAGE_CATEGORY/ADD_IMAGE_CATEGORY_ERROR",
    GET_IMAGE_CATEGORY_REQUEST: "IMAGE_CATEGORY/GET_IMAGE_CATEGORY_REQUEST",
    GET_IMAGE_CATEGORY_SUCCESS: "IMAGE_CATEGORY/GET_IMAGE_CATEGORY_SUCCESS",
    GET_IMAGE_CATEGORY_ERROR: "IMAGE_CATEGORY/GET_IMAGE_CATEGORY_ERROR",
    UPDATE_IMAGE_CATEGORY_REQUEST: "IMAGE_CATEGORY/UPDATE_IMAGE_CATEGORY_REQUEST",
    UPDATE_IMAGE_CATEGORY_SUCCESS: "IMAGE_CATEGORY/UPDATE_IMAGE_CATEGORY_SUCCESS",
    UPDATE_IMAGE_CATEGORY_ERROR: "IMAGE_CATEGORY/UPDATE_IMAGE_CATEGORY_ERROR",
    GET_PAGE_IMAGE_CATEGORY_REQUEST: "IMAGE_CATEGORY/GET_PAGE_IMAGE_CATEGORY_REQUEST",
    GET_PAGE_IMAGE_CATEGORY_SUCCESS: "IMAGE_CATEGORY/GET_PAGE_IMAGE_CATEGORY_SUCCESS",
    GET_PAGE_IMAGE_CATEGORY_ERROR: "IMAGE_CATEGORY/GET_PAGE_IMAGE_CATEGORY_ERROR",
    DELETE_API_CATEGORY_REQUEST: "IMAGE_CATEGORY/DELETE_API_CATEGORY_REQUEST",
    DELETE_API_CATEGORY_SUCCESS: "IMAGE_CATEGORY/DELETE_API_CATEGORY_SUCCESS",
    DELETE_API_CATEGORY_ERROR: "IMAGE_CATEGORY/DELETE_API_CATEGORY_ERROR",

    getImageCategories: () => ({ type: actions.GET_ALL_IMAGE_CATEGORY_REQUEST}),

    createApiImageCategory: (payload) => ({
        type: actions.ADD_IMAGE_CATEGORY_REQUEST,
        payload,
    }),

    getImageCategoryById: (payload) => ({
        type: actions.GET_IMAGE_CATEGORY_REQUEST,
        payload,
    }),

    updateApiImageCategory: (payload) => ({
        type: actions.UPDATE_IMAGE_CATEGORY_REQUEST,
        payload,
    }),

    getPageImageCategory: (payload) => ({
        type: actions.GET_PAGE_IMAGE_CATEGORY_REQUEST,
        payload
    }),

    deleteApiCategory: (payload) => ({
        type: actions.DELETE_API_CATEGORY_REQUEST,
        payload
    }),

};

export default actions;
