import { all, takeLatest, fork, call, put } from "redux-saga/effects";
import actions from "./actions";
import { crawlPrinceAchievementService } from "@iso/services";

export function* crawlPrinceAchievement() {
  yield takeLatest(actions.CRAWL_PRINCE_ACHIEVEMENT_REQUEST, function* ({ payload }) {
    const {
      period,
      storeIds,
      resolve,
      reject
    } = payload;
    try {
      const response = yield call(crawlPrinceAchievementService.crawlPrinceAchievement, {
        period,
        storeIds,
        resolve,
        reject
      });
      yield put({
        type: actions.CRAWL_PRINCE_ACHIEVEMENT_SUCCESS,
        crawler: response,
      });
      yield resolve(response);
    } catch (e) {
      yield put({ type: actions.CRAWL_PRINCE_ACHIEVEMENT_ERROR });
      reject(e);
    }
  });
}

export function* getCrawlPrinceAchievement() {
  yield takeLatest(actions.GET_CRAWL_PRINCE_ACHIEVEMENT_REQUEST, function* () {
    try {
      const report = yield call(crawlPrinceAchievementService.getCrawlPrinceAchievement);
      yield put({
        type: actions.GET_CRAWL_PRINCE_ACHIEVEMENT_SUCCESS,
        crawler: report,
      });
    } catch (error) {
      yield put({
        type: actions.GET_CRAWL_PRINCE_ACHIEVEMENT_ERROR,
        error: error.response,
      });
    }
  });
}

export function* downloadCrawlPrinceAchievement() {
  yield takeLatest(actions.DOWNLOAD_CRAWL_PRINCE_ACHIEVEMENT_REQUEST, function* () {
    try {
      const report = yield call(crawlPrinceAchievementService.downloadFileCrawlPrinceAchievement);
      yield put({
        type: actions.DOWNLOAD_CRAWL_PRINCE_ACHIEVEMENT_SUCCESS,
        crawler: report,
      });
    } catch (error) {
      yield put({
        type: actions.DOWNLOAD_CRAWL_PRINCE_ACHIEVEMENT_ERROR,
        error: error.response,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(crawlPrinceAchievement)]);
  yield all([fork(getCrawlPrinceAchievement)]);
  yield all([fork(downloadCrawlPrinceAchievement)]);
}
