const actions = {
  GET_LIST_TOTAL_TIME_WORK: "GET_LIST_TOTAL_TIME_WORK",
  GET_LIST_TOTAL_TIME_WORK_SUCCESS: "GET_LIST_TOTAL_TIME_WORK_SUCCESS",
  GET_LIST_TOTAL_TIME_WORK_ERROR: "GET_LIST_TOTAL_TIME_WORK_ERROR",

  getListTotalTimeWork: (payload)=> ({
    type: actions.GET_LIST_TOTAL_TIME_WORK,
    payload,
  }),

};

export default actions;
