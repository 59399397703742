import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";

import BaseService from "./base.service";

export default class TaskCopyHistoryService extends BaseService {
    getTaskCopyHistory = ({ ...data }) => {
        return this.axios.post(API_ENDPOINTS.TASK_COPY_HISTORY_SEARCH, {
            ...data,
        });
    };
}
