const actions = {
    GET_LIST_CANCEL_POLICY_REQUEST: "CANCEL_POLICY/GET_LIST_CANCEL_POLICY_REQUEST",
    GET_LIST_CANCEL_POLICY_SUCCESS: "CANCEL_POLICY/GET_LIST_CANCEL_POLICY_SUCCESS",
    GET_LIST_CANCEL_POLICY_ERROR: "CANCEL_POLICY/GET_LIST_CANCEL_POLICY_ERROR",

    CRAWL_DATA_CANCEL_POLICY_REQUEST: "CANCEL_POLICY/CRAWL_DATA_CANCEL_POLICY_REQUEST",
    CRAWL_DATA_CANCEL_POLICY_SUCCESS: "CANCEL_POLICY/CRAWL_DATA_CANCEL_POLICY_SUCCESS",
    CRAWL_DATA_CANCEL_POLICY_ERROR: "CANCEL_POLICY/CRAWL_DATA_CANCEL_POLICY_ERROR",
    getListCancelPolicy: (payload) => ({
        type: actions.GET_LIST_CANCEL_POLICY_REQUEST,
        payload: payload,
    }),

    crawlDataCancelPolicy: (payload) => ({
        type: actions.CRAWL_DATA_CANCEL_POLICY_REQUEST,
        payload,
    }),
};

export default actions;
