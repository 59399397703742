import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";

import BaseService from "./base.service";

export default class RequestTimeService extends BaseService {
    fetchRequestTimes = ({ ...data }) => {
        return this.axios.get(API_ENDPOINTS.LIST_REQUEST_TIME, 
        {
            params: {
                sortBy: "register_date",
                ...data,
            },
        });
    };

    createRequestTime = ({
        requestTimes, arrayDeletes
      }) => {
        return this.axios.post(API_ENDPOINTS.CREATE_REQUEST_TIME, { requestTimes, arrayDeletes });
    };
}
