import actions from "@iso/redux/taskStoreConfig/actions";

const initialState = {
  exportTaskStoreConfigs: [],
  storeConfig: null,
  taskStoreResults: [],
  total: 0,
  taskStoreLoading: true,
  error: null,
  errorDelete: null,
  loading: false
};

export default function taskStoresConfig(state = initialState, action) {
  switch (action.type) {
    case actions.GET_STORES_REQUEST:
      return {
        ...state,
      };
    case actions.GET_STORES_SUCCESS:
      return {
        ...state,
        exportTaskStoreConfigs: action.stores,
      };
    case actions.GET_STORES_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actions.GET_TASK_STORE_CONFIG_REQUEST:
      return {
        ...state,
      };
    case actions.GET_TASK_STORE_CONFIG_SUCCESS:
      return {
        ...state,
        storeConfig: action.storeConfig,
      };
    case actions.GET_TASK_STORE_CONFIG_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actions.TASK_STORE_CONFIG_ADD_AND_EDIT_REQUEST:
      return {
        ...state,
      };
    case actions.TASK_STORE_CONFIG_ADD_AND_EDIT_SUCCESS:
      return {
        ...state,
      };
    case actions.TASK_STORE_CONFIG_ADD_AND_EDIT_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actions.DELETE_TASK_STORE_CONFIG_REQUEST:
      return {
        ...state,
      };
    case actions.DELETE_TASK_STORE_CONFIG_SUCCESS:
      return {
        ...state,
      };
    case actions.DELETE_TASK_STORE_CONFIG_ERROR:
      return {
        ...state,
        errorDelete: action.error,
      };
    case actions.TASK_STORE_CONFIG_RESET_FORM:
      return {
        ...state,
        storeConfig: null,
      }
    default:
      return state;
  }
}
