import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";

import BaseService from "./base.service";

export default class TotalTimeWorkService extends BaseService {
    fetchTotalTimeWorks = ({ ...data }) => {
        return this.axios.get(API_ENDPOINTS.LIST_TOTAL_TIME_WORK, 
        {
            params: {
                sortBy: "accountId",
                ...data,
            },
        });
    };
}
