import actions from "@iso/redux/apiImage/actions";

const initialState = {
  apiImageOtas: [],
  imageByStoreAndOta: [],
  imageStoreAndOta: [],
  total: 0,
  loading: true,
};

export default function ApiImageReducer(state = initialState, action) {
  switch (action.type) {
    case actions.ADD_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.ADD_IMAGE_SUCCESS:
      return {
        ...state,
        apiImage: action.apiImage,
        loading: false,
      };
    case actions.ADD_IMAGE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.SEARCH_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.SEARCH_IMAGE_SUCCESS:
      return {
        ...state,
        apiImageOtas: action.apiImageOtas,
        total: action.total,
        loading: false,
      };
    case actions.SEARCH_IMAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.IMAGE_BY_STORE_AND_OTA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.IMAGE_BY_STORE_AND_OTA_SUCCESS:
      return {
        ...state,
        imageByStoreAndOta: action.apiImageOtas,
        loading: false,
      };
    case actions.IMAGE_BY_STORE_AND_OTA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.IMAGE_STORE_AND_OTA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.IMAGE_STORE_AND_OTA_SUCCESS:
      return {
        ...state,
        imageStoreAndOta: action.apiImageOtas,
        loading: false,
      };
    case actions.IMAGE_STORE_AND_OTA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.DELETE_IMAGE_REQUEST:
      return {
        ...state,
      };
    case actions.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
      };
    case actions.DELETE_IMAGE_ERROR:
      return {
        ...state,
        errorDelete: action.error,
      };
    default:
      return state;
  }
}
