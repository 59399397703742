import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";

import BaseService from "./base.service";
import axios from "axios";
import {ACCESS_TOKEN_KEY} from "@iso/constants/common.constant";

export default class ApiImageService extends BaseService {
    createApiImage = (data) => {
        const formData = new FormData();
        data.attachs.forEach((image, index) => {
            if (data.otaIds.includes(image.otaId)) {
                formData.append(`${image.otaId}`, image);
            }
        });
        formData.append("storeId", data.storeId);
        formData.append("otaIds", JSON.stringify(data.otaIds));
        formData.append("categoryByOta", JSON.stringify(data.categoryByOta));
        return axios.post(`${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_API_PRE_ENDPOINT}${API_ENDPOINTS.API_IMAGE}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_KEY),
            },
        });
    };

    getApiImage = ({ ...data }) => {
        return this.axios.post(API_ENDPOINTS.API_IMAGE_SEARCH, {
            ...data,
        });
    };
    
    getApiImageByStoreAndOta = ({ ...data }) => {
        return this.axios.post(API_ENDPOINTS.API_IMAGE_BY_STORE_AND_OTA, {
            ...data,
        });
    };

    getApiImageStoreAndOta = ({ ...data }) => {
        return this.axios.post(API_ENDPOINTS.API_IMAGE_STORE_AND_OTA, {
            ...data,
        });
    };
    deleteApiImage = (apiImageOtaId) => {
        return this.axios.delete(API_ENDPOINTS.API_IMAGE_DELETE
            .replace("{apiImageOtaId}", apiImageOtaId));
    }
}
