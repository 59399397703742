import { all, takeLatest, takeEvery, put, fork, call } from "redux-saga/effects";

import {apiImageCategoryService} from "@iso/services";
import actions from "@iso/redux/apiImageCategory/actions";

function* getImageCategories() {
  yield takeEvery(actions.GET_ALL_IMAGE_CATEGORY_REQUEST, function* () {

    try {

      const response = yield call(apiImageCategoryService.getImageCategories);
      yield put({
        type: actions.GET_ALL_IMAGE_CATEGORY_SUCCESS,
        imageCategoryList: response?.rows || [],
        total: response ? response.total : 0
      });
    } catch (e) {
      yield put({
        type: actions.GET_ALL_IMAGE_CATEGORY_ERROR,
        error: e.message,
      });
    }
  });
}
function* createApiImageCategory() {
  yield takeEvery(actions.ADD_IMAGE_CATEGORY_REQUEST, function* ({ payload }) {

    const { data, resolve, reject } = payload;
    try {
      const apiImageCategory = yield call(apiImageCategoryService.createApiImageCategory, { ...data });
      yield put({
        type: actions.ADD_IMAGE_CATEGORY_SUCCESS,
        apiImageCategory,
      });
      yield resolve(apiImageCategory);
    } catch (e) {
      yield put({
        type: actions.ADD_IMAGE_CATEGORY_ERROR,
        error: e.message,
      });
      reject(e);
    }
  });
}

function* updateApiImageCategory() {
  yield takeEvery(actions.UPDATE_IMAGE_CATEGORY_REQUEST, function* ({ payload }) {

    const { data, resolve, reject } = payload;
    try {
      yield call(apiImageCategoryService.updateApiImageCategory, { ...data });
      yield put({
        type: actions.UPDATE_IMAGE_CATEGORY_SUCCESS,
      });
      yield resolve({});
    } catch (e) {
      yield put({
        type: actions.UPDATE_IMAGE_CATEGORY_ERROR,
        error: e.message,
      });
      reject({});
    }
  });
}

function* getImageCategoryById() {
  yield takeEvery(actions.GET_IMAGE_CATEGORY_REQUEST, function* ({ payload }) {
    const { apiImageCategoryId } = payload;
    try {
      const imageCategory = yield call(apiImageCategoryService.getImageCategoryById, { apiImageCategoryId });
      yield put({
        type: actions.GET_IMAGE_CATEGORY_SUCCESS,
        imageCategory,
      });
    } catch (error) {
      yield put({
        type: actions.GET_IMAGE_CATEGORY_ERROR,
        error: error.response,
      });
    }
  });
}

function* getPageImageCategory() {
  yield takeEvery(actions.GET_PAGE_IMAGE_CATEGORY_REQUEST, function* ({ payload }) {
    const {
      storeIds,
      name,
      otaIds,
      size,
      page,
      resolve,
      reject,
    } = payload;
    try {

      const response = yield call(apiImageCategoryService.getPageImageCategory, {
        storeIds,
        name,
        otaIds,
        size,
        page,
        resolve,
        reject,
      });
      yield put({
        type: actions.GET_PAGE_IMAGE_CATEGORY_SUCCESS,
        imageCategoryListPage: response?.imageCategoryListPage || [],
        total: response ? response.total : 0
      });
    } catch (e) {
      yield put({
        type: actions.GET_PAGE_IMAGE_CATEGORY_ERROR,
        error: e.message,
      });
    }
  });
}

export function* deleteApiCategory() {
  yield takeLatest(actions.DELETE_API_CATEGORY_REQUEST, function* ({payload}) {
    const { apiCategoryId, resolve, reject } = payload;
    try {
      const response = yield call(apiImageCategoryService.deleteApiCategory, {
        apiCategoryId,
        resolve,
        reject
      });
      yield put({
        type: actions.DELETE_API_CATEGORY_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.DELETE_API_CATEGORY_ERROR,
        error: e.message,
      });
      yield reject(e);
    }
  })
}

export default function* rootSaga() {
  yield all([
      fork(getImageCategories),
      fork(createApiImageCategory),
      fork(getImageCategoryById),
      fork(updateApiImageCategory),
      fork(getPageImageCategory),
      fork(deleteApiCategory),
  ]);
}
