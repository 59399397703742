import actions from "@iso/redux/taskCopyHistory/actions";

const initialState = {
  tasks: [],
  total: 0,
  loading: true,
};

export default function taskCopyHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_TASK_COPY_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_TASK_COPY_HISTORY_SUCCESS:
      return {
        ...state,
        tasks: action.tasks,
        total: action.total,
        loading: false,
      };
    case actions.GET_TASK_COPY_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
