const actions = {
    ADD_IMAGE_REQUEST: "IMAGE/ADD_IMAGE_REQUEST",
    ADD_IMAGE_SUCCESS: "IMAGE/ADD_IMAGE_SUCCESS",
    ADD_IMAGE_ERROR: "IMAGE/ADD_IMAGE_ERROR",
    SEARCH_IMAGE_REQUEST: "IMAGE/SEARCH_IMAGE_REQUEST",
    SEARCH_IMAGE_SUCCESS: "IMAGE/SEARCH_IMAGE_SUCCESS",
    SEARCH_IMAGE_ERROR: "IMAGE/SEARCH_IMAGE_ERROR",
    DELETE_IMAGE_REQUEST: "IMAGE/DELETE_IMAGE_REQUEST",
    DELETE_IMAGE_SUCCESS: "IMAGE/DELETE_IMAGE_SUCCESS",
    DELETE_IMAGE_ERROR: "IMAGE/DELETE_IMAGE_ERROR",

    IMAGE_BY_STORE_AND_OTA_REQUEST: "IMAGE/BY_STORE_AND_OTA_REQUEST",
    IMAGE_BY_STORE_AND_OTA_SUCCESS: "IMAGE/BY_STORE_AND_OTA_SUCCESS",
    IMAGE_BY_STORE_AND_OTA_ERROR: "IMAGE/BY_STORE_AND_OTA_ERROR",

    IMAGE_STORE_AND_OTA_REQUEST: "IMAGE/STORE_AND_OTA_REQUEST",
    IMAGE_STORE_AND_OTA_SUCCESS: "IMAGE/STORE_AND_OTA_SUCCESS",
    IMAGE_STORE_AND_OTA_ERROR: "IMAGE/STORE_AND_OTA_ERROR",

    createApiImage: (payload) => ({
        type: actions.ADD_IMAGE_REQUEST,
        payload: payload,
    }),

    getApiImage: (payload) => ({
        type: actions.SEARCH_IMAGE_REQUEST,
        payload: payload,
    }),

    getApiImageByStoreAndOta: (payload) => ({
        type: actions.IMAGE_BY_STORE_AND_OTA_REQUEST,
        payload: payload,
    }),

    getApiImageStoreAndOta: (payload) => ({
        type: actions.IMAGE_STORE_AND_OTA_REQUEST,
        payload: payload,
    }),

    deleteApiImage: (payload) => ({
        type: actions.DELETE_IMAGE_REQUEST,
        payload: payload,
    }),


};

export default actions;
