import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";

import BaseService from "./base.service";

export default class ApiImageCategoryService extends BaseService {
    getImageCategories = () => {
        return this.axios.get(API_ENDPOINTS.GET_LIST_API_CATEGORY);
    };
    createApiImageCategory = (data) => {
        return this.axios.post(API_ENDPOINTS.CREATE_API_IMAGE_CATEGORY, data);
    };
    getImageCategoryById = ({ apiImageCategoryId }) => {
        return this.axios.get(API_ENDPOINTS.API_IMAGE_CATEGORY_ID.replace("{imageCategoryId}", apiImageCategoryId));
    };
    updateApiImageCategory = (data) => {
        const { apiImageCategoryId } = data;
        return this.axios.put(API_ENDPOINTS.API_IMAGE_CATEGORY_ID.replace("{imageCategoryId}",apiImageCategoryId),{
            ...data,
        });
    };
    getPageImageCategory = ({ ...data }) => {
        return this.axios.post(API_ENDPOINTS.API_IMAGE_CATEGORY_SEARCH, {
            ...data,
        }, {});
    };
    deleteApiCategory = (data) => {
        return this.axios.delete(API_ENDPOINTS.DELETE_API_CATEGORY.replace("{apiCategoryId}", data.apiCategoryId));
    }
}
