const actions = {
  GET_CURRENT_USER: "GET_CURRENT_USER",
  GET_CURRENT_USER_SUCCESS: "GET_CURRENT_USER_SUCCESS",
  GET_CURRENT_USER_ERROR: "GET_CURRENT_USER_ERROR",
  UPDATE_CURRENT_USER: "UPDATE_CURRENT_USER",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_ERROR: "UPDATE_USER_ERROR",
  RESET_ACCOUNT_SETTING_STORE: "RESET_ACCOUNT_SETTING_STORE",
  getCurrentUser: () => ({
    type: actions.GET_CURRENT_USER,
  }),
  updateUser: (payload) => ({
    type: actions.UPDATE_CURRENT_USER,
    payload,
  }),
};
export default actions;
