const actions = {
  CRAWL_COUPON_REQUEST: "CRAWL_COUPON_REQUEST",
  CRAWL_COUPON_SUCCESS: "CRAWL_COUPON_SUCCESS",
  CRAWL_COUPON_ERROR: "CRAWL_COUPON_ERROR",

  GET_CRAWL_COUPON_REQUEST: "GET_CRAWL_COUPON_REQUEST",
  GET_CRAWL_COUPON_SUCCESS: "GET_CRAWL_COUPON_SUCCESS",
  GET_CRAWL_COUPON_ERROR: "GET_CRAWL_COUPON_ERROR",

  SAVE_DATA_AUTO_CRAWL_COUPON_REQUEST: "SAVE_DATA_AUTO_CRAWL_COUPON_REQUEST",
  SAVE_DATA_AUTO_CRAWL_COUPON_SUCCESS: "SAVE_DATA_AUTO_CRAWL_COUPON_SUCCESS",
  SAVE_DATA_AUTO_CRAWL_COUPON_ERROR: "SAVE_DATA_AUTO_CRAWL_COUPON_ERROR",

  GET_DATA_AUTO_CRAWL_COUPON_REQUEST: "GET_DATA_AUTO_CRAWL_COUPON_REQUEST",
  GET_DATA_AUTO_CRAWL_COUPON_SUCCESS: "GET_DATA_AUTO_CRAWL_COUPON_SUCCESS",
  GET_DATA_AUTO_CRAWL_COUPON_ERROR: "GET_DATA_AUTO_CRAWL_COUPON_ERROR",

  GET_DATA_AUTO_CRAWL_COUPON_6_MONTH_REQUEST: "GET_DATA_AUTO_CRAWL_COUPON_6_MONTH_REQUEST",
  GET_DATA_AUTO_CRAWL_COUPON_6_MONTH_SUCCESS: "GET_DATA_AUTO_CRAWL_COUPON_6_MONTH_SUCCESS",
  GET_DATA_AUTO_CRAWL_COUPON_6_MONTH_ERROR: "GET_DATA_AUTO_CRAWL_COUPON_6_MONTH_ERROR",

  crawlCoupon: (payload) => ({
    type: actions.CRAWL_COUPON_REQUEST,
    payload,
  }),

  saveDataAutoCrawlCoupon: (payload) => ({
    type: actions.SAVE_DATA_AUTO_CRAWL_COUPON_REQUEST,
    payload,
  }),

  getDataAutoCrawlCoupon: (payload) => ({
    type: actions.GET_DATA_AUTO_CRAWL_COUPON_REQUEST,
    payload,
  }),

  getCrawlCoupon: (payload) => ({
    type: actions.GET_CRAWL_COUPON_REQUEST,
    payload,
  }),
};
export default actions;
