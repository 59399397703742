import actions from "@iso/redux/apiCancelPolicy/actions";

const initialState = {
  listCancelPolicy: [],
  statusCrawlCancelPolicy: null,
  loading: true,
  loadingCrawlCancelPolicy: false,
};

export default function ApiCancelPolicyReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_LIST_CANCEL_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_LIST_CANCEL_POLICY_SUCCESS:
      return {
        ...state,
        listCancelPolicy: action.listCancelPolicy,
        statusCrawlCancelPolicy: action.statusCrawlCancelPolicy,
        loading: false,
      };
    case actions.GET_LIST_CANCEL_POLICY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.CRAWL_DATA_CANCEL_POLICY_REQUEST:
      return {
        ...state,
        statusCrawlCancelPolicy: 3,
        loadingCrawlCancelPolicy: true
      };
    case actions.CRAWL_DATA_CANCEL_POLICY_SUCCESS:
      return {
        ...state,
        loadingCrawlCancelPolicy: false
      };
    case actions.CRAWL_DATA_CANCEL_POLICY_ERROR:
      return {
        ...state,
        loadingCrawlCancelPolicy: false
      };
    default:
      return state;
  }
}
