import { all, takeEvery, put, fork, call, takeLatest } from "redux-saga/effects";

import {taskStoreConfigService} from "@iso/services";
import actions from "@iso/redux/taskStoreConfig/actions";

function* getStores() {
  yield takeEvery(actions.GET_STORES_REQUEST, function* ({ payload }) {
    try {
      const stores = yield call(taskStoreConfigService.getStores);
      yield put({
        type: actions.GET_STORES_SUCCESS,
        stores: stores,
      });
    } catch (e) {
      yield put({
        type: actions.GET_STORES_ERROR,
        error: e.message,
      });
    }
  });
}

function* getTaskStoreConfigById() {
  yield takeEvery(actions.GET_TASK_STORE_CONFIG_REQUEST, function* ({ payload }) {
    const { id, isStoreValue, resolve, reject } = payload;
    try {
      const storeConfig = yield call(taskStoreConfigService.getTaskStoreConfigById, id, isStoreValue);
      yield put({
        type: actions.GET_TASK_STORE_CONFIG_SUCCESS,
        storeConfig: storeConfig,
      });
      yield resolve(storeConfig);
    } catch (e) {
      yield put({
        type: actions.GET_TASK_STORE_CONFIG_ERROR,
        error: e.message,
      });
      reject(e);
    }
  });
}

function* taskStoreConfigAddAndEdit() {
  yield takeEvery(actions.TASK_STORE_CONFIG_ADD_AND_EDIT_REQUEST, function* ({ payload }) {
    const { data, resolve, reject } = payload;
    try {
      const response = yield call(taskStoreConfigService.taskStoreConfigAddAndEdit, data);
      yield put({
        type: actions.TASK_STORE_CONFIG_ADD_AND_EDIT_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.TASK_STORE_CONFIG_ADD_AND_EDIT_ERROR,
        error: e.message,
      });
      reject(e);
    }
  });
}

function* deleteTaskStoreConfig() {
  yield takeEvery(actions.DELETE_TASK_STORE_CONFIG_REQUEST, function* ({ payload }) {
    const { selectedConfigId, isStore, resolve, reject } = payload;
    try {
      const response = yield call(taskStoreConfigService.deleteTaskStoreConfig, selectedConfigId, isStore);
      yield put({
        type: actions.DELETE_TASK_STORE_CONFIG_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({
        type: actions.DELETE_TASK_STORE_CONFIG_ERROR,
        error: e,
      });
      reject(e);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getStores)]);
  yield all([fork(getTaskStoreConfigById)]);
  yield all([fork(taskStoreConfigAddAndEdit)]);
  yield all([fork(deleteTaskStoreConfig)]);
}
