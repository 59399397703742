import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";
import BaseService from "./base.service";

export default class CrawlCouponService extends BaseService {
  crawlCoupon = (data) => {
    return this.axios.post(API_ENDPOINTS.CRAWL_COUPON, data);
  }

  getCrawlCoupon = () => {
    return this.axios.get(API_ENDPOINTS.GET_CRAWL_COUPON);
  }

  saveDataAutoCrawlCoupon = (data) => {
    return this.axios.post(API_ENDPOINTS.SAVE_DATA_AUTO_CRAWL_COUPON, data);
  }

  getDataAutoCrawlCoupon = (data) => {
    return this.axios.post(API_ENDPOINTS.AUTO_CRAWL_COUPON, data);
  }
}
