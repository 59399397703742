const actions = {
  FETCH_API_ROOMS_REQUEST: "SELECT_API_ROOM/FET_API_ROOMS_REQUEST",
  FETCH_API_ROOMS_SUCCESS: "SELECT_API_ROOM/FET_API_ROOMS_SUCCESS",
  FETCH_API_ROOMS_ERROR: "SELECT_API_ROOM/FET_API_ROOMS_ERROR",
  FETCH_API_ROOMS_IN_IDS_REQUEST: "SELECT_API_ROOM_IN_IDS/FET_API_ROOMS_REQUEST",
  FETCH_API_ROOMS_IN_IDS_SUCCESS: "SELECT_API_ROOM_IN_IDS/FET_API_ROOMS_SUCCESS",
  FETCH_API_ROOMS_IN_IDS_ERROR: "SELECT_API_ROOM_IN_IDS/FET_API_ROOMS_ERROR",
  TOGGLE_SELECT_API_ROOM: "SELECT_API_ROOM/TOGGLE_SELECT_API_ROOM",

  fetchApiRooms: (payload) => ({
    type: actions.FETCH_API_ROOMS_REQUEST,
    payload,
  }),

  toggleSelectApiRoomOta: () => ({
    type: actions.TOGGLE_SELECT_API_ROOM,
  }),
};

export default actions;
