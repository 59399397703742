import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";
import BaseService from "./base.service";

export default class CrawlPlanSaleService extends BaseService {
  crawlPlanSale = (data) => {
    return this.axios.post(API_ENDPOINTS.CRAWL_PLAN_SALE, data);
  }
  downloadFileCrawlPlanSale = () => {
    return this.axios.get(API_ENDPOINTS.CRAWL_PLAN_SALE_DOWNLOAD);
  }
  getCrawlPlanSale = () => {
    return this.axios.get(API_ENDPOINTS.GET_CRAWL_PLAN_SALE);
  }
}
