const actions = {
    GET_CRAWL_PLAN_REQUEST: "CRAWL_PLAN/GET_CRAWL_PLAN_REQUEST",
    GET_CRAWL_PLAN_SUCCESS: "CRAWL_PLAN/GET_CRAWL_PLAN_SUCCESS",
    GET_CRAWL_PLAN_ERROR: "CRAWL_PLAN/GET_CRAWL_PLAN_ERROR",

    CRAWL_PLAN_REQUEST: "CRAWL_PLAN/CRAWL_PLAN_REQUEST",
    CRAWL_PLAN_SUCCESS: "CRAWL_PLAN/CRAWL_PLAN_SUCCESS",
    CRAWL_PLAN_ERROR: "CRAWL_PLAN/CRAWL_PLAN_ERROR",

    GET_LIST_USER_REQUEST: "CRAWL_PLAN/GET_LIST_USER_REQUEST",
    GET_LIST_USER_SUCCESS: "CRAWL_PLAN/GET_LIST_USER_SUCCESS",
    GET_LIST_USER_ERROR: "CRAWL_PLAN/GET_LIST_USER_ERROR",

    getListCrawlPlan: (payload) => ({ type: actions.GET_CRAWL_PLAN_REQUEST, payload }),

    crawlPlan: (payload) => ({ type: actions.CRAWL_PLAN_REQUEST, payload }),

    getListUser: (payload) => ({ type: actions.GET_LIST_USER_REQUEST, payload }),

};

export default actions;
