import { all, takeLatest, fork, call, put } from "redux-saga/effects";
import actions from "./actions";
import { crawlPlanSaleService } from "@iso/services";

export function* crawlPlanSale() {
  yield takeLatest(actions.CRAWL_PLAN_SALE_REQUEST, function* ({ payload }) {
    const {
      startReceiptDate,
      endReceiptDate,
      storeIds,
      saleDestination,
      keyword,
      resolve,
      reject
    } = payload;
    try {
      const response = yield call(crawlPlanSaleService.crawlPlanSale, {
        startReceiptDate,
        endReceiptDate,
        storeIds,
        saleDestination,
        keyword,
        resolve,
        reject
      });
      yield put({
        type: actions.CRAWL_PLAN_SALE_SUCCESS,
        crawler: response,
      });
      yield resolve(response);
    } catch (e) {
      yield put({ type: actions.CRAWL_PLAN_SALE_ERROR });
      reject(e);
    }
  });
}

export function* getCrawlPlanSale() {
  yield takeLatest(actions.GET_CRAWL_PLAN_SALE_REQUEST, function* () {
    try {
      const report = yield call(crawlPlanSaleService.getCrawlPlanSale);
      yield put({
        type: actions.GET_CRAWL_PLAN_SALE_SUCCESS,
        crawler: report,
      });
    } catch (error) {
      yield put({
        type: actions.GET_CRAWL_PLAN_SALE_ERROR,
        error: error.response,
      });
    }
  });
}

export function* downloadCrawlPlanSale() {
  yield takeLatest(actions.DOWNLOAD_CRAWL_PLAN_SALE_REQUEST, function* () {
    try {
      const report = yield call(crawlPlanSaleService.downloadFileCrawlPlanSale);
      yield put({
        type: actions.DOWNLOAD_CRAWL_PLAN_SALE_SUCCESS,
        crawler: report,
      });
    } catch (error) {
      yield put({
        type: actions.DOWNLOAD_CRAWL_PLAN_SALE_ERROR,
        error: error.response,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(crawlPlanSale)]);
  yield all([fork(getCrawlPlanSale)]);
  yield all([fork(downloadCrawlPlanSale)]);
}
