import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";
import BaseService from "./base.service";

export default class ContentAchievementService extends BaseService {

  getAllOtas = () => {
    return this.axios.get(API_ENDPOINTS.LIST_ALL_OTAS);
  };

  getContentAchievement = ({ ...data }) => {
    return this.axios.post(API_ENDPOINTS.CONTENT_ACHIEVEMENT, {
      ...data,
    });
  }

  getTaskDeliveryAchievement = ({ ...data }) => {
    return this.axios.post(API_ENDPOINTS.TASK_DELIVERY_ACHIEVEMENT, {
      ...data,
    });
  }
}
