import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";
import BaseService from "./base.service";

export default class CrawlSaleStatisticService extends BaseService {
  crawlSaleStatistic = (data) => {
    return this.axios.post(API_ENDPOINTS.CRAWL_SALE_STATISTIC, data);
  }

  getCrawlSaleStatistic = () => {
    return this.axios.get(API_ENDPOINTS.GET_CRAWL_SALE_STATISTIC);
  }
}
