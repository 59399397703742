const actions = {
    GET_ROOM_REQUEST: "ROOM/GET_ROOM_REQUEST",
    GET_ROOM_SUCCESS: "ROOM/GET_ROOM_SUCCESS",
    GET_ROOM_ERROR: "ROOM/GET_ROOM_ERROR",

    CREATE_ROOM_REQUEST: "ROOM/CREATE_ROOM_REQUEST",
    CREATE_ROOM_SUCCESS: "ROOM/CREATE_ROOM_SUCCESS",
    CREATE_ROOM_ERROR: "ROOM/CREATE_ROOM_ERROR",
    
    GET_ROOM_DETAIL_REQUEST: "ROOM/CREATE_ROOM_DETAIL_REQUEST",
    GET_ROOM_DETAIL_SUCCESS: "ROOM/CREATE_ROOM_DETAIL_SUCCESS",
    GET_ROOM_DETAIL_ERROR: "ROOM/CREATE_ROOM_DETAIL_ERROR",

    DELETE_API_ROOM_OTA_REQUEST: "ROOM/DELETE_API_ROOM_OTA_REQUEST",
    DELETE_API_ROOM_OTA_SUCCESS: "ROOM/DELETE_API_ROOM_OTA_SUCCESS",
    DELETE_API_ROOM_OTA_ERROR: "ROOM/DELETE_API_ROOM_OTA_ERROR",

    SYNC_DATA_ROOM_REQUEST: "ROOM/SYNC_DATA_ROOM_REQUEST",
    SYNC_DATA_ROOM_SUCCESS: "ROOM/SYNC_DATA_ROOM_SUCCESS",
    SYNC_DATA_ROOM_ERROR: "ROOM/SYNC_DATA_ROOM_ERROR",

    RELOAD_ROOM_REQUEST: "ROOM/RELOAD_ROOM_REQUEST",
    RELOAD_ROOM_SUCCESS: "ROOM/RELOAD_ROOM_SUCCESS",
    RELOAD_ROOM_ERROR: "ROOM/RELOAD_ROOM_ERROR",

    UPDATE_DATA_ROOM_REQUEST: "ROOM/UPDATE_DATA_ROOM_REQUEST",
    UPDATE_DATA_ROOM_SUCCESS: "ROOM/UPDATE_DATA_ROOM_SUCCESS",
    UPDATE_DATA_ROOM_ERROR: "ROOM/UPDATE_DATA_ROOM_ERROR",

    OPEN_SALE_SINGLE_ROOM_REQUEST: "ROOM/OPEN_SALE_SINGLE_ROOM_REQUEST",
    OPEN_SALE_SINGLE_ROOM_SUCCESS: "ROOM/OPEN_SALE_SINGLE_ROOM_SUCCESS",
    OPEN_SALE_SINGLE_ROOM_ERROR: "ROOM/OPEN_SALE_SINGLE_ROOM_ERROR",

    STOP_SELLING_ROOM_REQUEST: "ROOM/STOP_SELLING_ROOM_REQUEST",
    STOP_SELLING_ROOM_SUCCESS: "ROOM/STOP_SELLING_ROOM_SUCCESS",
    STOP_SELLING_ROOM_ERROR: "ROOM/STOP_SELLING_ROOM_ERROR",

    getRooms: (payload) => ({ type: actions.GET_ROOM_REQUEST, payload }),

    createRoom: (payload) => ({
        type: actions.CREATE_ROOM_REQUEST,
        payload,
    }),

    getRoom: (payload) => ({
        type: actions.GET_ROOM_DETAIL_REQUEST,
        payload,
    }),

    deleteApiRoomOta: (payload) => ({
        type: actions.DELETE_API_ROOM_OTA_REQUEST,
        payload
    }),
    syncDataToSite: (payload) => ({
        type: actions.SYNC_DATA_ROOM_REQUEST,
        payload,
    }),
    reloadRoom: (payload) => ({
        type: actions.RELOAD_ROOM_REQUEST,
        payload,
    }),
    updateDataFromSite: (payload) => ({
        type: actions.UPDATE_DATA_ROOM_REQUEST,
        payload,
    }),

    openSaleSingleRoom: (payload) => ({
        type: actions.OPEN_SALE_SINGLE_ROOM_REQUEST,
        payload,
    }),
    stopSellingRoom: (payload) => ({
        type: actions.STOP_SELLING_ROOM_REQUEST,
        payload,
    }),
};

export default actions;
