const actions = {
  GET_PASSWORD_HISTORIES_REQUEST: "GET_PASSWORD_HISTORIES_REQUEST",
  GET_PASSWORD_HISTORIES_SUCCESS: "GET_PASSWORD_HISTORIES_SUCCESS",
  GET_PASSWORD_HISTORIES_ERROR: "GET_PASSWORD_HISTORIES_ERROR",
  GET_METADATA_PASSWORD_HISTORY_REQUEST: "GET_METADATA_PASSWORD_HISTORY_REQUEST",
  GET_METADATA_PASSWORD_HISTORY_SUCCESS: "GET_METADATA_PASSWORD_HISTORY_SUCCESS",
  GET_METADATA_PASSWORD_HISTORY_ERROR: "GET_METADATA_PASSWORD_HISTORY_ERROR",
  TOGGLE_PASSWORD_HISTORY: "TOGGLE_PASSWORD_HISTORY",
  RESET_PASSWORD_HISTORY_STORE: "RESET_PASSWORD_HISTORY_STORE",
  getPasswordHistories: (payload) => ({
    type: actions.GET_PASSWORD_HISTORIES_REQUEST,
    payload,
  }),
  getMetaData: (payload) => ({
    type: actions.GET_METADATA_PASSWORD_HISTORY_REQUEST,
    payload,
  }),
  togglePasswordHistory: () => ({ type: actions.TOGGLE_PASSWORD_HISTORY }),
};

export default actions;
