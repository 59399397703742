import { all, takeEvery, put, fork, call, takeLatest } from "redux-saga/effects";

import {
  requestTimeService,
  taskService,
} from "@iso/services";
import actions from "./actions";


function* getListUser() {
  yield takeEvery(actions.GET_LIST_USER, function* ({ payload }) {
    const { values  } = payload;
    try {
      const userAssigneeList = yield call(taskService.getUserAssigneeList, values);
      yield put({
        type: actions.GET_LIST_USER_SUCCESS,
        userAssigneeList: userAssigneeList.accounts,
      });
    } catch (e) {
      yield put({ type: actions.GET_LIST_USER_ERROR });
    }
  });
}

function* getListRequestTime() {
  yield takeEvery(actions.GET_LIST_REQUEST_TIME, function* ({ payload }) {
    const { size, ...data } = payload;
    try {
      const requestTimes = yield call(requestTimeService.fetchRequestTimes, {
        size,
        ...data,
      });
      yield put({
        type: actions.GET_LIST_REQUEST_TIME_SUCCESS,
        requestTimes: requestTimes ? requestTimes.rows : [],
        total: requestTimes ? requestTimes.total : 0,
      });
    } catch (e) {
      yield put({ type: actions.GET_LIST_REQUEST_TIME_ERROR });
    }
  });
}

function* postResquestTime() {
  yield takeLatest(actions.POST_REQUEST_TIME, function* ({ payload }) {
    const { data, arrayDelete, resolve, reject } = payload;
    try {
      const response = yield call(requestTimeService.createRequestTime, {
        requestTimes : data.request_time,
        arrayDeletes : arrayDelete
      });
      yield put({
        type: actions.POST_REQUEST_TIME_SUCCESS,
        request_time: data.request_time,
      });
      yield resolve(response);
    } catch (e) {
      reject();
      yield put({ type: actions.POST_REQUEST_TIME_ERROR });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getListUser),
    fork(getListRequestTime),
    fork(postResquestTime)
  ]);
}
