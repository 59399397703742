import {Row, Col} from 'antd';
import React from 'react';
import PropTypes from "prop-types";
import moment from "moment";
import {useIntl} from "react-intl";
import {DATE_TIME_FORMAT} from "@iso/constants/common.constant";

const DisplayUserUpdate = ({updatedAt, updatedUserName}) => {
    const { messages } = useIntl();
    const colLayout = {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 8 },
        lg: { span: 6 },
        xl: { span: 6 },
        xxl: { span: 4 }
    };
    const styleRow = {
        justifyContent: 'end'
    };
    const styleP = {
        whiteSpace: 'pre'
    };
    return (
         (updatedUserName != null || updatedAt != null) && (
        <Row style={styleRow} >
            <Col>
                <p style={styleP}>{(updatedUserName ?? "") + "　" +  messages["DisplayUserUpdate.updatedAt"] + "：" + (updatedAt ? moment(updatedAt).format(DATE_TIME_FORMAT) : "")}</p>
            </Col>
        </Row>
        )
    );
};

DisplayUserUpdate.propTypes = {
    updatedAt: PropTypes.string,
    updatedUserName: PropTypes.string,
};

export default DisplayUserUpdate;
