const actions = {
  ADD_CONTENT_REQUEST: "CONTENT/ADD_CONTENT_REQUEST",
  ADD_CONTENT_SUCCESS: "CONTENT/ADD_CONTENT_SUCCESS",
  ADD_CONTENT_ERROR: "CONTENT/ADD_CONTENT_ERROR",

  createContent: (payload) => ({
    type: actions.ADD_CONTENT_REQUEST,
    payload,
  }),
};
export default actions;
