import actions from "./actions";

const initialState = {
  task: {},
  loading: false,
  requesting: false,
  requestingTypes: false,
  updateRequesting: false,
  totalTimeWorks: [],
  error: null,
  total: 0,
};

export default function totalTimeWorkReducer(state = initialState, action) {
  const { totalTimeWorks, total, error } = action;
  switch (action.type) {
    case actions.GET_LIST_TOTAL_TIME_WORK: {
      return {
        ...state,
        totalTimeWorks: [],
        requesting: true,
        total: 0,
        error: null,
      };
    }
    case actions.GET_LIST_TOTAL_TIME_WORK_SUCCESS: {
      return {
        ...state,
        totalTimeWorks,
        requesting: false,
        total,
        error: null,
      };
    }
    case actions.GET_LIST_TOTAL_TIME_WORK_ERROR: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}
