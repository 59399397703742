const actions = {
  GET_AUTO_COPY_TASK_REQUEST: "AUTO_COPY_TASK/GET_AUTO_COPY_TASK_REQUEST",
  GET_AUTO_COPY_TASK_SUCCESS: "AUTO_COPY_TASK/GET_AUTO_COPY_TASK_SUCCESS",
  GET_AUTO_COPY_TASK_ERROR: "AUTO_COPY_TASK/GET_AUTO_COPY_TASK_ERROR",

  CREATE_AUTO_COPY_TASK_REQUEST: "AUTO_COPY_TASK/CREATE_AUTO_COPY_TASK_REQUEST",
  CREATE_AUTO_COPY_TASK_SUCCESS: "AUTO_COPY_TASK/CREATE_AUTO_COPY_TASK_SUCCESS",
  CREATE_AUTO_COPY_TASK_ERROR: "AUTO_COPY_TASK/CREATE_AUTO_COPY_TASK_ERROR",
  
  UPDATE_AUTO_COPY_TASK_REQUEST: "AUTO_COPY_TASK/UPDATE_AUTO_COPY_TASK_REQUEST",
  UPDATE_AUTO_COPY_TASK_SUCCESS: "AUTO_COPY_TASK/UPDATE_AUTO_COPY_TASK_SUCCESS",
  UPDATE_AUTO_COPY_TASK_ERROR: "AUTO_COPY_TASK/UPDATE_AUTO_COPY_TASK_ERROR",

  DETAIL_AUTO_COPY_TASK_REQUEST: "AUTO_COPY_TASK/DETAIL_AUTO_COPY_TASK_REQUEST",
  DETAIL_AUTO_COPY_TASK_SUCCESS: "AUTO_COPY_TASK/DETAIL_AUTO_COPY_TASK_SUCCESS",
  DETAIL_AUTO_COPY_TASK_ERROR: "AUTO_COPY_TASK/DETAIL_AUTO_COPY_TASK_ERROR",

  getAutoCopyTasks: (payload) => ({
    type: actions.GET_AUTO_COPY_TASK_REQUEST,
    payload,
  }),

  createAutoCopyTask: (payload) => ({
    type: actions.CREATE_AUTO_COPY_TASK_REQUEST,
    payload,
  }),

  updateAutoCopyTask: (payload) => ({
    type: actions.UPDATE_AUTO_COPY_TASK_REQUEST,
    payload,
  }),

  getAutoCopyTaskDetail: (payload) => ({
    type: actions.DETAIL_AUTO_COPY_TASK_REQUEST,
    payload,
  })
};

export default actions;
