import { all, takeLatest, fork, call, put } from "redux-saga/effects";
import actions from "./actions";
import { contentService } from "@iso/services";

export function* createContent() {
  yield takeLatest(actions.ADD_CONTENT_REQUEST, function* ({ payload }) {
    const { data, resolve, reject } = payload;
    try {
      const response = yield call(contentService.createContent, { ...data });
      yield put({
        type: actions.ADD_CONTENT_SUCCESS,
      });
      yield resolve(response);
    } catch (e) {
      yield put({ type: actions.ADD_CONTENT_ERROR });
      reject(e);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(createContent)]);
}
