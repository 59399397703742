import { all, takeLatest, fork, call, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";
import {crawlCouponService, crawlPlanSaleService} from "@iso/services";

export function* crawlCoupon() {
  yield takeLatest(actions.CRAWL_COUPON_REQUEST, function* ({ payload }) {
    const {
      startReceiptDate,
      endReceiptDate,
      startUseDate,
      endUseDate,
      coupons,
      storeIds,
      resolve,
      reject
    } = payload;
    try {
      const response = yield call(crawlCouponService.crawlCoupon, {
        startReceiptDate,
        endReceiptDate,
        startUseDate,
        endUseDate,
        coupons,
        storeIds,
        resolve,
        reject
      });
      yield put({
        type: actions.CRAWL_COUPON_SUCCESS,
        crawler: response
      });
      yield resolve(response);
    } catch (e) {
      yield put({ type: actions.CRAWL_COUPON_ERROR });
      reject(e);
    }
  });
}

export function* getCrawlCoupon() {
  yield takeLatest(actions.GET_CRAWL_COUPON_REQUEST, function* () {
    try {
      const report = yield call(crawlCouponService.getCrawlCoupon);
      yield put({
        type: actions.GET_CRAWL_COUPON_SUCCESS,
        crawler: report,
      });
    } catch (error) {
      yield put({
        type: actions.GET_CRAWL_COUPON_ERROR,
        error: error.response,
      });
    }
  });
}

export function* saveDataAutoCrawlCoupon() {
  yield takeLatest(actions.SAVE_DATA_AUTO_CRAWL_COUPON_REQUEST, function* ({ payload }) {
    const {
      type,
      startReceiptDate,
      endReceiptDate,
      startUseDate,
      endUseDate,
      coupons,
      storeIds,
      resolve,
      reject
    } = payload;
    try {
      const response = yield call(crawlCouponService.saveDataAutoCrawlCoupon, {
        type,
        startReceiptDate,
        endReceiptDate,
        startUseDate,
        endUseDate,
        coupons,
        storeIds,
        resolve,
        reject
      });
      yield put({
        type: actions.SAVE_DATA_AUTO_CRAWL_COUPON_SUCCESS,
        crawler: response
      });
      yield resolve(response);
    } catch (e) {
      yield put({ type: actions.SAVE_DATA_AUTO_CRAWL_COUPON_ERROR });
      reject(e);
    }
  });
}

export function* getDataAutoCrawlCoupon() {
  yield takeEvery(actions.GET_DATA_AUTO_CRAWL_COUPON_REQUEST, function* ({ payload }) {
    const {
      type,
      resolve,
      reject
    } = payload;
    try {
      const response = yield call(crawlCouponService.getDataAutoCrawlCoupon, { type });
      yield put({
        type: actions.GET_DATA_AUTO_CRAWL_COUPON_SUCCESS,
        crawlerData: response,
      });
      resolve(response)
    } catch (error) {
      yield put({
        type: actions.GET_DATA_AUTO_CRAWL_COUPON_ERROR,
        error: error.response,
      });
      reject(error)
    }
  });
}

export default function* rootSaga() {
  yield all([fork(crawlCoupon)]);
  yield all([fork(getCrawlCoupon)]);
  yield all([fork(saveDataAutoCrawlCoupon)]);
  yield all([fork(getDataAutoCrawlCoupon)]);
}
