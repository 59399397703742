import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";

import BaseService from "./base.service";

export default class AutoCopyTaskService extends BaseService {
    getAutoCopyTasks = (data) => {
        return this.axios.post(API_ENDPOINTS.AUTO_COPY_TASK_SEARCH, data);
    };

    createAutoCopyTask = (data) => {
        return this.axios.post(API_ENDPOINTS.CREATE_AUTO_COPY_TASK, data);
    };
    
    updateAutoCopyTask = (data) => {
        return this.axios.put(API_ENDPOINTS.UPDATE_AUTO_COPY_TASK.replace("{autoCopyTaskId}", data.autoCopyTaskId), data);
    };
    
    fetchAutoCopyTask = ({ autoCopyTaskId }) => {
        return this.axios.get(API_ENDPOINTS.DETAIL_AUTO_COPY_TASK.replace("{autoCopyTaskId}", autoCopyTaskId));
    };
}
