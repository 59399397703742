const actions = {
    GET_API_OPTION_VALUES_REQUEST: "OPTION_VALUES/GET_API_OPTION_VALUES_REQUEST",
    GET_API_OPTION_VALUES_SUCCESS: "OPTION_VALUES/GET_API_OPTION_VALUES_SUCCESS",
    GET_API_OPTION_VALUES_ERROR: "OPTION_VALUES/GET_API_OPTION_VALUES_ERROR",
    
    getApiOptionValues: (payload) => ({
        type: actions.GET_API_OPTION_VALUES_REQUEST,
        payload: payload,
    }),

};

export default actions;
