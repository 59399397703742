import BaseService from "./base.service";
import { API_ENDPOINTS } from "@iso/constants/apiEndpoints.constant";

export default class TaskStoreConfigService extends BaseService {
  getStores = () => {
    return this.axios.get(API_ENDPOINTS.LIST_STORES);
  };

  getTaskStoreConfigById = (id, isStore) => {
    return this.axios.get(API_ENDPOINTS.TASK_STORE_CONFIG
        .replace("{storeId}", id)
        .replace("{isStore}", isStore))
  };

  taskStoreConfigAddAndEdit = (data) => {
    return this.axios.post(API_ENDPOINTS.TASK_STORE_CONFIG_ADD_AND_EDIT, data);
  }

  deleteTaskStoreConfig = (storeId, isStore) => {
    return this.axios.delete(API_ENDPOINTS.TASK_STORE_CONFIG_DELETE
        .replace("{storeId}", storeId)
        .replace("{isStore}", isStore));
  }
}
