import actions from "./actions";

const initialState = {
  error: null,
  total: 0,
  loading: false,
  createAutoCopyTaskLoading: false,
  autoCopyTasks: [],
  autoCopyTaskDetail: []
};

export default function autoCopyTaskReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_AUTO_COPY_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_AUTO_COPY_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        total: action.total,
        autoCopyTasks: action.autoCopyTasks
      };
    case actions.GET_AUTO_COPY_TASK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case actions.CREATE_AUTO_COPY_TASK_REQUEST:
      return {
        ...state,
        createAutoCopyTaskLoading: true,
      };
    case actions.CREATE_AUTO_COPY_TASK_SUCCESS:
      return {
        ...state,
        createAutoCopyTaskLoading: false,
      };
    case actions.CREATE_AUTO_COPY_TASK_ERROR:
      return {
        ...state,
        createAutoCopyTaskLoading: false,
        error: action.error,
      };

    case actions.DETAIL_AUTO_COPY_TASK_REQUEST:
      return {
        ...state,
      };
    case actions.DETAIL_AUTO_COPY_TASK_SUCCESS:
      return {
        ...state,
        loading: true,
        autoCopyTaskDetail: action.autoCopyTask
      };
    case actions.DETAIL_AUTO_COPY_TASK_ERROR:
      return {
        ...state,
        loading: false,
      };
    
    default:
      return state;
  }
}
