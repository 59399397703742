const actions = {
  FETCH_STORES_REQUEST: "SELECT_STORE/FET_STORES_REQUEST",
  FETCH_STORES_SUCCESS: "SELECT_STORE/FET_STORES_SUCCESS",
  FETCH_STORES_ERROR: "SELECT_STORE/FET_STORES_ERROR",
  FETCH_METADATA_SELECT_STORE_REQUEST:
    "SELECT_STORE/FET_METADATA_SELECT_STORE_REQUEST",
  FETCH_METADATA_SELECT_STORE_SUCCESS:
    "SELECT_STORE/FET_METADATA_SELECT_STORE_SUCCESS",
  FETCH_METADATA_SELECT_STORE_ERROR: "SELECT_STORE/FET_METADATA_SELECT_STORE_ERROR",
  TOGGLE_SELECT_STORE: "SELECT_STORE/TOGGLE_SELECT_STORE",
  fetchStores: (payload) => ({
    type: actions.FETCH_STORES_REQUEST,
    payload,
  }),
  fetchSelectStoreMetadata: () => ({
    type: actions.FETCH_METADATA_SELECT_STORE_REQUEST,
  }),
  toggleSelectStore: () => ({
    type: actions.TOGGLE_SELECT_STORE,
  }),
};

export default actions;
