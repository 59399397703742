const taskStoreConfigAction = {
  GET_STORES_REQUEST: "GET_TASKS_REQUEST",
  GET_STORES_SUCCESS: "GET_STORES_SUCCESS",
  GET_STORES_ERROR: "GET_STORES_ERROR",

  GET_TASK_STORE_CONFIG_REQUEST: "GET_TASK_STORE_CONFIG_REQUEST",
  GET_TASK_STORE_CONFIG_SUCCESS: "GET_TASK_STORE_CONFIG_SUCCESS",
  GET_TASK_STORE_CONFIG_ERROR: "GET_TASK_STORE_CONFIG_ERROR",

  TASK_STORE_CONFIG_ADD_AND_EDIT_REQUEST: "TASK_STORE_CONFIG_ADD_AND_EDIT_REQUEST",
  TASK_STORE_CONFIG_ADD_AND_EDIT_SUCCESS: "TASK_STORE_CONFIG_ADD_AND_EDIT_SUCCESS",
  TASK_STORE_CONFIG_ADD_AND_EDIT_ERROR: "TASK_STORE_CONFIG_ADD_AND_EDIT_ERROR",

  DELETE_TASK_STORE_CONFIG_REQUEST: "DELETE_TASK_STORE_CONFIG_REQUEST",
  DELETE_TASK_STORE_CONFIG_SUCCESS: "DELETE_TASK_STORE_CONFIG_SUCCESS",
  DELETE_TASK_STORE_CONFIG_ERROR: "DELETE_TASK_STORE_CONFIG_ERROR",

  TASK_STORE_RESET_TABLE: "TASK_STORE_RESET_TABLE",
  TASK_STORE_CONFIG_RESET_FORM: "TASK_STORE_CONFIG_RESET_FORM",

  getStores: () => ({ type: taskStoreConfigAction.GET_STORES_REQUEST }),
  taskStoreResetTable: () => ({ type: taskStoreConfigAction.TASK_STORE_RESET_TABLE }),
  taskStoreConfigResetForm: () => ({ type: taskStoreConfigAction.TASK_STORE_CONFIG_RESET_FORM }),
  getTaskStoreConfigById: (payload) => ({
    type: taskStoreConfigAction.GET_TASK_STORE_CONFIG_REQUEST,
    payload
  }),
  taskStoreConfigAddAndEdit: (payload) => ({
    type: taskStoreConfigAction.TASK_STORE_CONFIG_ADD_AND_EDIT_REQUEST,
    payload
  }),
  deleteTaskStoreConfig: (payload) => ({
    type: taskStoreConfigAction.DELETE_TASK_STORE_CONFIG_REQUEST,
    payload
  })
};

export default taskStoreConfigAction;
