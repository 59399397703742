import actions from "./actions";

const initialState = {
  error: null,
  loading: false,
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.ADD_CONTENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.ADD_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case actions.ADD_CONTENT_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
