const actions = {
  CHAIN_METADATA_REQUEST: "CHAIN_METADATA_REQUEST",
  CHAIN_METADATA_SUCCESS: "CHAIN_METADATA_SUCCESS",
  CHAIN_METADATA_ERROR: "CHAIN_METADATA_ERROR",
  CHAINS_REQUEST: "CHAINS_REQUEST",
  CHAINS_SUCCESS: "CHAINS_SUCCESS",
  CHAINS_ERROR: "CHAIN_ERROR",
  CHAIN_REQUEST: "CHAIN_REQUEST",
  CHAIN_SUCCESS: "CHAIN_SUCCESS",
  CHAIN_ERROR: "CHAIN_ERROR",
  ADD_CHAIN_REQUEST: "ADD_CHAIN_REQUEST",
  ADD_CHAIN_SUCCESS: "ADD_CHAIN_SUCCESS",
  ADD_CHAIN_ERROR: "ADD_CHAIN_ERROR",
  EDIT_CHAIN_REQUEST: "EDIT_CHAIN_REQUEST",
  EDIT_CHAIN_SUCCESS: "EDIT_CHAIN_SUCCESS",
  EDIT_CHAIN_ERROR: "EDIT_CHAIN_ERROR",
  CHAIN_OPTIONS_REQUEST: "CHAIN_OPTIONS_REQUEST",
  CHAIN_OPTIONS_SUCCESS: "CHAIN_OPTIONS_SUCCESS",
  CHAIN_OPTIONS_ERROR: "CHAIN_OPTIONS_ERROR",
  RESET_CHAIN_STORE: "RESET_CHAIN_STORE",
  getMetadata: (payload) => ({ type: actions.CHAIN_METADATA_REQUEST, payload }),
  getChains: (payload) => ({ type: actions.CHAINS_REQUEST, payload }),
  getChain: (payload) => ({ type: actions.CHAIN_REQUEST, payload }),
  addChain: (payload) => ({ type: actions.ADD_CHAIN_REQUEST, payload }),
  editChain: (payload) => ({ type: actions.EDIT_CHAIN_REQUEST, payload }),
  getChainOptions: (payload) => ({ type: actions.CHAIN_OPTIONS_REQUEST, payload }),
};
export default actions;
