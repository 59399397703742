const actions = {
  GET_STOP_WATCHES_REQUEST: "DASHBOARD/GET_STOP_WATCHES_REQUEST",
  GET_STOP_WATCHES_SUCCESS: "DASHBOARD/GET_STOP_WATCHES_SUCCESS",
  GET_STOP_WATCHES_ERROR: "DASHBOARD/GET_STOP_WATCHES_ERROR",

  GET_UNCOMPLETED_TASK_REQUEST: "DASHBOARD/GET_UNCOMPLETED_TASK_REQUEST",
  GET_UNCOMPLETED_TASK_SUCCESS: "DASHBOARD/GET_UNCOMPLETED_TASK_SUCCESS",
  GET_UNCOMPLETED_TASK_ERROR: "DASHBOARD/GET_UNCOMPLETED_TASK_ERROR",

  GET_CHILDREN_TASK_REQUEST: "DASHBOARD/GET_CHILDREN_TASK_REQUEST",
  GET_CHILDREN_TASK_SUCCESS: "DASHBOARD/GET_CHILDREN_TASK_SUCCESS",
  GET_CHILDREN_TASK_ERROR: "DASHBOARD/GET_CHILDREN_TASK_ERROR",

  GET_CATEGORIES_REQUEST: "DASHBOARD/GET_CATEGORIES_REQUEST",
  GET_CATEGORIES_SUCCESS: "DASHBOARD/GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_ERROR: "DASHBOARD/GET_CATEGORIES_ERROR",

  GET_ASSIGNEES_REQUEST: "DASHBOARD/GET_ASSIGNEES_REQUEST",
  GET_ASSIGNEES_SUCCESS: "DASHBOARD/GET_ASSIGNEES_SUCCESS",
  GET_ASSIGNEES_ERROR: "DASHBOARD/GET_ASSIGNEES_ERROR",

  GET_DIRECTOR_REQUEST: "DASHBOARD/GET_DIRECTOR_REQUEST",
  GET_DIRECTOR_SUCCESS: "DASHBOARD/GET_DIRECTOR_SUCCESS",
  GET_DIRECTOR_ERROR: "DASHBOARD/GET_DIRECTOR_ERROR",

  getStopWatches: (payload) => ({
    type: actions.GET_STOP_WATCHES_REQUEST,
    payload,
  }),

  getUncompletedTasks: (payload) => ({
    type: actions.GET_UNCOMPLETED_TASK_REQUEST,
    payload,
  }),

  getChildrenTask: (payload) => ({
    type: actions.GET_CHILDREN_TASK_REQUEST,
    payload,
  }),

  getCategories: () => ({
    type: actions.GET_CATEGORIES_REQUEST,
  }),

  getAssignees: (payload) => ({
    type: actions.GET_ASSIGNEES_REQUEST,
    payload,
  }),

  getDirectors: () => ({
    type: actions.GET_DIRECTOR_REQUEST,
  })
};

export default actions;
