import { all, takeLatest, takeEvery, put, fork, call } from "redux-saga/effects";
import { get, reverse, pickBy, pick, identity } from "lodash";

import {
  templateService,
} from "@iso/services";
import actions from "./actions";

export function* findTemplateByCategoryId() {
  yield takeLatest(actions.GET_TEMPLATE_REQUEST, function* ({ payload }) {
    const { categoryId, subCategoryId, resolve, reject } = payload;
    try {
      let template = "";
      if (categoryId !== null && categoryId !== undefined) {
        template = yield call(templateService.findTemplateByCategoryId, {categoryId});
      } else if (subCategoryId !== null && subCategoryId !== undefined) {
        template = yield call(templateService.findTemplateBySubCategoryId, {subCategoryId});
      }
      yield put({
        type: actions.GET_TEMPLATE_SUCCESS,
        template: template,
      });
      resolve(template);
    } catch (e) {
      yield put({ type: actions.GET_TEMPLATE_ERROR });
      reject(e);
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(findTemplateByCategoryId),
  ]);
}
